import { Typography } from "@mui/material";
import React, {useState} from "react";

export interface ITypewriterProps {
    text: string
    delay: number
    infinite: boolean
}

const Typewriter: React.FunctionComponent<ITypewriterProps> = (props: ITypewriterProps) => {
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    React.useEffect(() => {
        let timeout: any;

        if (currentIndex <= props.text.length) {
            timeout = setTimeout(() => {
                setCurrentText(prevText => prevText + props.text[currentIndex]);
                setCurrentIndex(prevIndex => prevIndex + 1);
            }, props.delay);

        } else if (props.infinite) { // ADD THIS CHECK
            setCurrentIndex(0);
            setCurrentText('');
        }

        return () => clearTimeout(timeout);
    }, [currentIndex, props.delay, props.infinite, props.text]);

    return <Typography sx={{color:'gray', fontSize: 'small'}}>{currentText}</Typography>;
};

export default Typewriter;
import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    TextareaAutosize,
    Button,
} from '@mui/material';
import useHomeHeaderStyles from "../../../shared/styles";
import {useTranslation} from "react-i18next";

const ContactUs = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const classes = useHomeHeaderStyles();
    const { t } = useTranslation();

    const submitForm = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        console.log({ email, firstName, subject, message });
    };

    return (
        <Box sx={classes.formContainer}>
            <Typography variant="h4" sx={classes.formHeading}>
                {t('homepage.contact0')}
            </Typography>
            <Box
                sx={classes.form}
                component="form"
                noValidate
                autoComplete="off"
            >
                <TextField
                    label={t('homepage.contact1')}
                    variant="outlined"
                    fullWidth
                    sx={classes.inputField}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />

                <TextField
                    label={t('homepage.contact2')}
                    variant="outlined"
                    fullWidth
                    sx={classes.inputField}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <TextField
                    label={t('homepage.contact3')}
                    variant="outlined"
                    fullWidth
                    sx={classes.inputField}
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                />

                <TextareaAutosize
                    aria-label="minimum height"
                    minRows={6}
                    placeholder={t('homepage.contact4')}
                    style={{width: '100%',
                        marginBottom: '20px',
                        fontSize: '16px',
                        padding: '10px'}}
                    spellCheck
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />

                <Button
                    variant="contained"
                    color="primary"
                    sx={{ width: '200px', fontSize: '16px' }}
                    onClick={submitForm}
                >
                    {t('homepage.contact5')}
                </Button>
                
            </Box>
        </Box>
    );
};

export default ContactUs;
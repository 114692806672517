import React, { useEffect, useRef, useState } from "react";
import StreamingAvatar, {
    AvatarQuality, StreamingEvents, TaskMode, TaskType, VoiceEmotion,
} from "@heygen/streaming-avatar";
import {CircularProgress, Box, Avatar} from "@mui/material";
import { useMemoizedFn } from "ahooks";
import { FaStop } from "react-icons/fa";
import LoadingButton from "@mui/lab/LoadingButton";
import ChromaKeyVideo from "./ChromaKeyVideo";
import {getAvatarImg} from "../../../model/Chatbot";

const avatarNameToId:{[key: string]: string} = {
    "Sofia": "37f4d912aa564663a1cf8d63acd0e1ab",
    "Sofia in office": "ef08039a41354ed5a20565db899373f3",
    "Sofia outdoor": "336b72634e644335ad40bd56462fc780",
    "Wayne": "Wayne_20240711",
    "Raj in office": "eb0a8cc8046f476da551a5559fbb5c82",
    "Raj outdoor": "cc2984a6003a4d5194eb58a4ad570337",
    "Vicky": "3c8a703d9d764938ae522b43401a59c2",
    "Vicky outdoor": "fa7b34fe0b294f02b2fca6c1ed2c7158",
    "Angelina outdoor": "73c84e2b886940099c5793b085150f2f",
    "Angelina in office": "c20f4bdddbe041ecba98d93444f8b29b",
    "Aiden": "43c34c4285cb4b6c81856713c70ba23b",
    "Aiden outdoor": "2c57ba04ef4d4a5ca30a953d0791e7e3",
    "Monica in Sleeveless": "",
    "Eric": "Eric_public_pro2_20230608",
    "Tyler": "Tyler-incasualsuit-20220721",
    "Anna": "Anna_public_3_20240108",
    "Susan": "Susan_public_2_20240328",
    "Josh": "josh_lite3_20230714",
    // there are more avatars possible, they are not listed here, all of their background (green) needs to be removed
}

export interface IInteractiveAvatar2Props {
    userInteracted: boolean
    textToSay: string
    botId: string
    avatarName: string
    avatarIdCustom?: string
    language: string
    buttonColor: string
    quality?: AvatarQuality
    backgroundImageUrl?: string
}

const InteractiveAvatar2: React.FC<IInteractiveAvatar2Props> = (props: IInteractiveAvatar2Props) => {
    const [stream, setStream] = useState<MediaStream | undefined>();
    const [previewImage, setPreviewImage] = useState<string>(getAvatarImg(props.avatarName || ""));
    const [topPos, setTopPos] = useState<number>(0);
    const [isTalking, setIsTalking] = useState<boolean>(false);
    const [removeGreenBackground, setRemoveGreenBackground] = useState<boolean>(false);
    const [talkingInterruped, setTalkingInterruped] = useState<boolean>(false);
    const mediaStream = useRef<HTMLVideoElement>(null);
    const avatar = useRef<StreamingAvatar | null>(null);


    const getAvatarId = (avatarName: string, customAvatarId?: string) => {
        
        if (customAvatarId && customAvatarId !== "") {
            return customAvatarId
        }
        
        if (avatarNameToId.hasOwnProperty(avatarName)) {
            const name = avatarNameToId[avatarName]
            if (name.length === 0) {
                setRemoveGreenBackground(true)
            }
            return avatarNameToId[avatarName]
        }
        setRemoveGreenBackground(true)
        return avatarName
    }
    
    // Fetch Access Token
    const fetchAccessToken = useMemoizedFn(async (): Promise<string> => {
        try {
            const response = await fetch("https://int.botbucket.de/api/chatbot/" + props.botId + "/heygen/generateToken", {
                method: "POST",
            });
            const token = await response.json();
            
            if (!token['previewImageUrl'].includes("default")) {
                setPreviewImage(token['previewImageUrl'])
            }
            
            
            if (props.botId === "8350924f-c329-4573-95d3-de6bc6ca843a") {
                // setTopPos(-100)
                setTopPos(0)
            }
            return token['token'];
        } catch (error) {
            console.error("Error fetching access token:", error);
            return "";
        }
    });

    // Start Avatar Session
    const startSession = useMemoizedFn(async () => {
        const newToken = await fetchAccessToken();

        if (!newToken) {
            console.log("Failed to fetch access token.");
            return;
        }

        avatar.current = new StreamingAvatar({
            token: newToken,
        });

        // Event Listeners
        avatar.current.on(StreamingEvents.AVATAR_START_TALKING, (e) => {
            setIsTalking(true)
        });
        avatar.current.on(StreamingEvents.AVATAR_STOP_TALKING, (e) => {
            setIsTalking(false)
            setTalkingInterruped(false)
        });
        avatar.current.on(StreamingEvents.STREAM_DISCONNECTED, () => {
            console.log("Stream disconnected");
            startSession()
            // endSession();
        });
        avatar.current.on(StreamingEvents.STREAM_READY, (event: any) => {
            console.log(">>>>> Stream ready:", event.detail);
            setStream(event.detail);
        });

        try {
            await avatar.current.createStartAvatar({
                quality: props.quality || AvatarQuality.Low,
                avatarName: getAvatarId(props.avatarName, props.avatarIdCustom),
                // knowledgeId: knowledgeId, // Or use a custom `knowledgeBase`.
                voice: {
                    rate: 1.0, // 0.5 ~ 1.5
                    emotion: VoiceEmotion.EXCITED,
                },
                language: props.language
            });

            // setData(res);
            // Default to voice mode
            // await avatar.current.startVoiceChat();
            // setChatMode("voice_mode");
            avatar.current.closeVoiceChat();
            
        } catch (error: any) {
            console.error("Error starting avatar session:", error);
        }
    });

    // Handle Interrupt
    const handleInterrupt = useMemoizedFn(async () => {
        if (!avatar.current) {
            return;
        }
        try {
            setTalkingInterruped(true)
            await avatar.current.interrupt();
        } catch (e: any) {
            console.log(e.message || "Error during interrupt.");
        }
    });

    // Handle Speak
    const handleSpeak = useMemoizedFn(async () => {
        if (!avatar.current) {
            console.log("Avatar API not initialized");
            return;
        }

        try {
            await avatar.current.speak({
                text: props.textToSay!,
                taskType: TaskType.REPEAT,
                taskMode: TaskMode.SYNC,
            });
        } catch (e: any) {
            console.log(e.message || "Error during speak.");
        }
    });

    // End Session
    const endSession = useMemoizedFn(async () => {
        if (avatar.current) {
            await avatar.current.stopAvatar();
            setStream(undefined);
            avatar.current = null;
        }
    });

    // Cleanup on Unmount
    useEffect(() => {
        return () => {
            endSession();
        };
    }, [endSession]);

    // Handle Stream
    useEffect(() => {
        if (stream && mediaStream.current) {
            mediaStream.current.srcObject = stream;
            mediaStream.current.onloadedmetadata = () => {
                mediaStream.current?.play();
                console.log("Playing");
            };
        }
    }, [stream]);
    
    useEffect(() => {
        if (props.userInteracted) {
            startSession()
        }
        // eslint-disable-next-line
    }, [props.userInteracted]);


    useEffect(() => {
        if (props.textToSay) {
            handleSpeak()    
        }
        // eslint-disable-next-line
    }, [props.textToSay]);

    return (
        <Box
            sx={{
                position: "absolute",
                width: "100%", height: "100%",
                // zIndex: -1, // Lower z-index to place it behind other elements
                display: "flex",
                flexDirection: "column",
                alignItems: "center", // Centers items horizontally
                justifyContent: "center", // Centers items vertically
                gap: 4,
                background: "transparent",
            }}
        >
            {props.backgroundImageUrl && props.backgroundImageUrl !== "" &&
                <img
                    src={props.backgroundImageUrl}
                    alt=""
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
            }
            {stream ? (
                <>
                    <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center",
                        width: "100%", overflow: "hidden", top: topPos || 0,  }}>
                        
                        {removeGreenBackground ? (
                            <ChromaKeyVideo stream={stream}/>
                        ) : (                        
                            <video ref={mediaStream} autoPlay playsInline >
                                <track kind="captions" />
                            </video>
                        )}
                    </Box>
                </>
            ) : (
                
                <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center", 
                    height: "100%", top: topPos || 0}} >
                    {(!props.avatarIdCustom || props.avatarIdCustom === "") &&
                        <Avatar alt="" src={previewImage} style={{ width: '200px', height: '200px' }} />
                    }
                    {props.userInteracted && (
                        <CircularProgress
                            sx={{
                                color: props.buttonColor,
                                position: "absolute", // Position the spinner absolutely
                                top: "50%",           // Center vertically
                                left: "50%",          // Center horizontally
                                transform: "translate(-50%, -50%)", // Offset to the exact center
                            }}
                        />
                    )}
                    
                </Box>
            )}

            {/* IconButton positioned absolutely at the bottom center */}
            {isTalking && <LoadingButton
                loading={talkingInterruped}
                size="small"
                sx={{
                    position: 'absolute',
                    bottom: 16, // Adjust the bottom spacing as needed
                    left: '50%',
                    transform: 'translateX(-50%)',
                    color: props.buttonColor,
                    zIndex: 2000, // Ensure the IconButton is on top
                }}
                onClick={handleInterrupt}
            >
                <FaStop />
            </LoadingButton>}
        </Box>
    );
};

export default InteractiveAvatar2;

import { CircularProgress, Grid, SxProps } from '@mui/material';
import * as React from 'react';
import { useMemo } from 'react';

export interface IInlineLoaderProps {
    sx?: SxProps
    size?: 'small' | 'medium' | 'large'
}

const InlineLoader: React.FunctionComponent<IInlineLoaderProps> = (props: IInlineLoaderProps) => {
    const size = useMemo(() => {
        switch (props.size) {
            case 'large':
                return "10em"
            case 'medium':
                return "5em"
            case 'small':
            default:
                return "2em"
        }
    }, [props.size])

    return (
        <>
            <Grid container sx={props.sx} justifyContent="center" alignContent="center">
                <Grid item>
                    <CircularProgress color="error" size={size} />
                </Grid>
            </Grid>
        </>
    );
}

export default InlineLoader;
import Cookies from "universal-cookie";
import axios from 'axios';

const API_URL = 'https://int.botbucket.de/api';


export const transcribeAudio = async (file: File): Promise<string> => {

    const cookies = new Cookies()
    
    const formData = new FormData();
    formData.append('file', file, file.name); // Ensure filename is included

    const headers = {
        'Authorization': 'Bearer ' + (cookies.get("token") ?? ''),
        'X-ID-PROVIDER': cookies.get("x-id-provider"),
        // Do not set 'Content-Type'; let the browser set it automatically
    };

    const response = await axios.post(`${API_URL}/protocol/transcribe`, formData, {
        headers: headers
    });

    return response.data.text;
};

export const decodeUnicode = (str: string): string =>  {
    if (str === undefined) {
        return str;
    }
    return str.replace(/\\u[\dA-F]{4}/gi,
        (match: string) => {
            return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
        });
}

export const cleanLinks = (inLink: string): string => {
    return inLink
        .replaceAll("*001*", "*")
        .replaceAll("*010*", "%")
        .replaceAll("*020*", "=")
        .replaceAll("*030*", "?")
        .replaceAll("*040*", ",")
        .replaceAll("*050*", "'")
        .replaceAll("*060*", ";")
        .replaceAll("*070*", "&")
        .replaceAll("*080*", "_")
        .replaceAll("*090*", "!")
        .replaceAll("*000*", "/")
        .replaceAll("*100*", ":")
        .replaceAll("*110*", "#")
        .replaceAll("*111*", "(")
        .replaceAll("*112*", ")")
        .replaceAll("*113*", "{")
        .replaceAll("*114*", "}")
        .replaceAll(".BotPDF", "")
        .replaceAll(".BotIMG", "")
        .replaceAll(".txt", "")
}
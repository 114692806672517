import ApiClient, { ClientOptions } from "./ApiClient";
import { createContext, useContext } from "react";
import { useNotificationContext } from "../shared/contexts/NotificationContext";
import { FileEntity } from "../model/FileEntity";
import { MessageResponse } from "../model/MessageResponse";

export default class FileApiClient extends ApiClient {
    public getAllFiles = async (options?: ClientOptions): Promise<FileEntity[]> => {
        return this.get(`/file/all`, options);
    }

    public createFile = async (file: Pick<FileEntity, "name" | "size" | "md5Hash">, options?: ClientOptions): Promise<FileEntity> => {
        return this.post('/file', { body: file, ...options });
    }

    public getFile = async (fileId: string, options?: ClientOptions): Promise<FileEntity> => {
        return this.get(`/file/${fileId}`, options);
    }

    public deleteFile = async (fileId: string, options?: ClientOptions): Promise<MessageResponse> => {
        return this.delete(`/file/${fileId}`, options);
    }

    public verifyFile = async (fileId: string, options?: ClientOptions): Promise<FileEntity> => {
        return this.patch(`/file/${fileId}/verify`, options);
    }
    public updateFileTopic = async (fileId: string, topic?: string, options?: ClientOptions): Promise<FileEntity> => {
        return this.put(`/file/${fileId}/topic`, { body: { topic: topic ?? null }, ...options });
    }

}


export const FileApiClientContext = createContext<FileApiClient>(new FileApiClient())

export const useFileApiClient = (defaultConfig?: ClientOptions) => {
    const context = useContext(FileApiClientContext)
    context.notificationCtx = useNotificationContext()
    if (defaultConfig) {
        context.defaultConfig = defaultConfig
    }
    return context
}
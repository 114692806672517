import * as React from 'react';
import {Embeddings} from '../../../model/Chatbot';
import {
    Dialog,
    DialogContent,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    DialogActions, Button, Slider, Typography, Checkbox, FormControlLabel
} from '@mui/material';
import DialogHeader from '../../../shared/components/dialogs/DialogHeader';

export interface IChatSettingsModalProps {
    open: boolean
    onClose(): void
    embeddings: Embeddings
    setEmbeddings(e: Embeddings): void
    nlpModel: string
    setNlpModel(s: string): void
    nlpTask: string
    setNlpTask(s: string): void
    maxLen: number
    setMaxLen(v: number): void
    maxNewTokens: number
    setMaxNewTokens(v: number): void
    temperature: number
    setTemperature(t: number): void
    topP: number
    setTopP(top: number): void
    defaultSysPromt: string
    setDefaultSysPromt(p: string): void
    fetchSimilarDocs: number
    setFetchSimilarDocs(n: number): void
    similarityScoreThreshold: number
    setSimilarityScoreThreshold(n: number): void
    speechUtterance: SpeechSynthesisUtterance
    autoReadResults: boolean
    setAutoReadResults(b: boolean): void
    checkAnswers: boolean
    setCheckAnswers(b: boolean): void
    addSources: boolean
    setAddSources(b: boolean): void
}

const ChatSettingsModal: React.FunctionComponent<IChatSettingsModalProps> = (props: IChatSettingsModalProps) => {

    // const { t } = useTranslation()
    
    const [selectedVoice, setSelectedVoice] = React.useState<string>("")

    React.useEffect(() => {
        console.log("utterance voice changed...")
        if (props.speechUtterance.voice) {
            setSelectedVoice(props.speechUtterance.voice.name)
        } else {
            console.log("No voice selected in utterance")
        }    
    }, [props.speechUtterance.voice])

    React.useEffect(() => {
        window.speechSynthesis.getVoices().forEach(voice => {
            if (voice.name === selectedVoice) {
                props.speechUtterance.voice = voice
            }
        })
        // eslint-disable-next-line
    }, [selectedVoice])
    
    
    const onClose = () => {
        // setEmbeddings(Embeddings.SENTENCE_TRANSFORMERS_ALL_MINILM_L6_V2)
        props.onClose && props.onClose()
    }

    return (
        <>
            <Dialog
                open={!!(props.open)}
                PaperProps={{ sx: { backgroundColor: "white" } }}
                disableEnforceFocus
                fullWidth
                maxWidth={'sm'}
                scroll="paper"
                onClose={onClose}
            >
                <DialogHeader
                    headline={"Chat-Settings"}
                    closeDialog={onClose} />
                <DialogContent>
                    <Grid container spacing={2} justifyContent={"center"}>
                        <Grid item xs={12}>
                            {" "}
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="embedding-label">{"Embeddings"}</InputLabel>
                                <Select
                                    labelId="embedding-label"
                                    value={props.embeddings}
                                    onChange={(e) => props.setEmbeddings(e.target.value as Embeddings)}
                                    label={"Embeddings"}
                                >
                                    {Object.values(Embeddings).map(e => <MenuItem key={e} value={e}>
                                        {e}
                                    </MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl variant="outlined" fullWidth>
                                <TextField
                                    type='number'
                                    value={props.fetchSimilarDocs}
                                    onChange={(e) => props.setFetchSimilarDocs(+e.target.value)}
                                    label={"# chunks to fetch"}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="nlpModel-label">{"NLP-Model"}</InputLabel>
                                <Select
                                    labelId="nlpModel-label"
                                    value={props.nlpModel}
                                    onChange={(e) => props.setNlpModel(e.target.value)}
                                    label={"NLP-Model"}
                                >
                                    {["OpenAI/gpt-3.5-turbo", "OpenAI/gpt-4-32k", "OpenAI/gpt-4-turbo-preview", "OpenAI/gpt-4o", "OpenAI/gpt-4o-mini", 
                                        "AlephAlpha/luminous-base", "AlephAlpha/luminous-extended", "AlephAlpha/luminous-supreme",
                                        "meta/llama-3-70b-chat", "meta/llama-2-70b-chat", "meta/llama-2-13b-chat", "meta/llama-2-7b-chat",
                                        "snowflake/snowflake-arctic-instruct", 
                                        "mistralai/mistral-7b-instruct", 
                                        "zephyr-7b-beta", 
                                        "openchat_3.5-awq", 
                                        "mixtral-8x7b-instruct", 
                                        "google-deepmind/gemma-7b-it"
                                    ].map(e => <MenuItem key={e} value={e}>
                                        {e}
                                    </MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="nlpTask-label">{"NLP-Task"}</InputLabel>
                                <Select
                                    labelId="nlpTask-label"
                                    value={(props.nlpTask === "RAG" ? "RAG - Retriever Augmented Generation" : "CIS - Conversational Information Seeking")}
                                    onChange={(e) => props.setNlpTask(e.target.value.slice(0, 3))}
                                    label={"NLP-Task"}
                                >
                                    {["RAG - Retriever Augmented Generation", "CIS - Conversational Information Seeking"].map(e => <MenuItem key={e} value={e}>
                                        {e}
                                    </MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth>
                                <TextField
                                    type='number'
                                    value={props.maxLen}
                                    onChange={(e) => props.setMaxLen(+e.target.value)}
                                    label={"Overal Length (max_length, input + output)"}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth>
                                <TextField
                                    type='number'
                                    value={props.maxNewTokens}
                                    onChange={(e) => props.setMaxNewTokens(+e.target.value)}
                                    label={"Answer length (max_new_tokens)"}
                                />
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth>
                                
                                <Typography gutterBottom>Temperature: {props.temperature}</Typography>
                                <Slider
                                    value={props.temperature}
                                    onChange={(event: Event, newValue: number | number[]) => {props.setTemperature(newValue as number)}}
                                    aria-labelledby="input-slider"
                                    aria-label="Temperature"
                                    min={0.01} max={5} step={0.01}
                                    valueLabelDisplay={"auto"}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth>

                                <Typography gutterBottom>similarityScoreThreshold: {props.similarityScoreThreshold}</Typography>
                                <Slider
                                    value={props.similarityScoreThreshold}
                                    onChange={(event: Event, newValue: number | number[]) => {props.setSimilarityScoreThreshold(newValue as number)}}
                                    aria-labelledby="input-slider"
                                    aria-label="similarityScoreThreshold"
                                    min={0.0} max={1} step={0.01}
                                    valueLabelDisplay={"auto"}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>

                                <TextField
                                    label="Default System Promt"
                                    value={props.defaultSysPromt}
                                    onChange={(e) => props.setDefaultSysPromt(e.target.value)}
                                    multiline
                                    rows={8}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel control={<Checkbox checked={props.autoReadResults} 
                                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setAutoReadResults(event.target.checked)} />} 
                                              label="Auto-read results" />
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="speech-label">{"Stimme"}</InputLabel>
                                <Select
                                    labelId="speech-label"
                                    value={selectedVoice}
                                    onChange={(e) => setSelectedVoice(e.target.value)}
                                    label={"Stimme"}
                                >
                                    {window.speechSynthesis.getVoices().map(e => <MenuItem key={e.name} value={e.name}>
                                        {e.name}
                                    </MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                            <FormControlLabel control={<Checkbox checked={props.checkAnswers}
                                                                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCheckAnswers(event.target.checked)} />}
                                              label="Check Answers" />
                        </Grid>
                        <Grid item xs={8}>
                            This will check each answer on compliance. Takes a bit longer to answer questions. Will do 1-2 extra requests to the LLM.
                        </Grid>
                        
                        <Grid item xs={4}>
                            <FormControlLabel control={<Checkbox checked={props.addSources}
                                                                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setAddSources(event.target.checked)} />}
                                              label="Add sources" />
                        </Grid>
                        <Grid item xs={8}>
                            Add best matching sources to the answer. Will do an extra request to the LLM. 
                        </Grid>
                        
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent={"right"}>
                        <Grid item>
                            <Button variant="contained" 
                                    onClick={() => onClose()}
                            >
                                OK
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ChatSettingsModal;
import {Box, IconButton, Link, Typography} from '@mui/material';
import * as React from 'react';
import useHomeHeaderStyles from "../styles";
import { Link as RouterLink } from 'react-router-dom'
import HelperBot from "./HelperBot";
import {useHelperBotContext} from "../contexts/HelperBotContext";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {useTheme} from "@mui/material/styles";

export interface IFooterProps {
}

const FooterBar: React.FunctionComponent<IFooterProps> = (props: IFooterProps) => {
    const classes = useHomeHeaderStyles();
    const theme = useTheme()
    const {setQuestionToAsk } = useHelperBotContext()

    // const chatbotClient = useChatbotApiClient({ preventNotification: true })

    // eslint-disable-next-line
    // const {questionToAsk, setQuestionToAsk } = useHelperBotContext()
    // <Button onClick={()=>setQuestionToAsk("Was ist BotBucket?")}>Was ist BotBucket?</Button>

    /*
    useEffect(() => {
        puppeteer.launch()
            .then((browser: Browser) => {
                return browser.newPage()
            })
            .then((page: Page) => {
                page.goto("https://www.cubert-hyperspectral.com/", {
                    //wait for content to load 
                    waitUntil: 'networkidle0',
                })
                return page
            })
            .then((page: Page) => {
                // @ts-ignore
                return page.evaluate(() =>
                    Array.from(document.querySelectorAll('ul.grid > li a')).map(a => a.href)
                )
            })
            .then((imgUrl) => {
                console.log(imgUrl);
            })
        // .finally(() => browser.close())
    }, [])
    */
    
    
    return (
        <>
            <Box sx={[classes.footerContainer]}>
                <Typography sx={classes.footerDate}>© 2023 YOUniquehorns GmbH &nbsp;&nbsp;
                    <Link component={RouterLink} to="/impressum" target="_blank" underline="none">
                        See Impressum
                    </Link>
                    <IconButton size={"small"} sx={{margin: "0px", padding: "0 0 7px 0", color: theme.palette.primary.main}}
                                onClick={()=>setQuestionToAsk("Wer sind die YOUniquehorns?")}>
                        <HelpOutlineOutlinedIcon sx={{width: "12px"}}/>
                    </IconButton>
                </Typography>
            </Box>
            <HelperBot />
        </>
    );
};

export default FooterBar;


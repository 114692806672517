import { Tooltip } from '@mui/material';
import * as React from 'react';

export interface IDisabledToolTipProps {
    disabled?: boolean
    tooltip: string
}

const DisabledToolTip: React.FunctionComponent<React.PropsWithChildren<IDisabledToolTipProps>> = (props: React.PropsWithChildren<IDisabledToolTipProps>) => {
    if (props.disabled) {
        return <Tooltip title={props.tooltip}>
            <span>{props.children}</span>
        </Tooltip>
    }
    return (
        <>{props.children}</>
    );
}

export default DisabledToolTip;
import {createContext, useContext} from "react";
import * as React from "react";

export interface HelperBotContext {
    questionToAsk: string
    setQuestionToAsk(q:string): void
}

const defaultHelperBotContext: HelperBotContext = {
    questionToAsk: "",
    setQuestionToAsk: function (): void {
        throw new Error("Function not implemented.");
    },
}

export const HelperBotContextHolder = createContext<HelperBotContext>(defaultHelperBotContext);

export const useHelperBotContext = () => {
    return useContext(HelperBotContextHolder);
};


export interface IHelperBotContextProps {}

export const HelperBotContextProvider: React.FunctionComponent<
    React.PropsWithChildren<IHelperBotContextProps>
> = (props: React.PropsWithChildren<IHelperBotContextProps>) => {

    const [questionToAsk, setQuestionToAsk] = React.useState<string>("");

    const helperBotCtx: HelperBotContext = {
        questionToAsk,
        setQuestionToAsk
    };

    return (
        <>
            <HelperBotContextHolder.Provider value={helperBotCtx}>
                {props.children}
            </HelperBotContextHolder.Provider>
        </>
    );


}
import React, { useRef, useEffect } from 'react';

interface ChromaKeyVideoProps {
    stream: MediaStream;
}

const ChromaKeyVideo: React.FC<ChromaKeyVideoProps> = ({ stream }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const animationFrameId = useRef<number>();
    const previousFrame = useRef<ImageData | null>(null); // Using useRef instead of let

    useEffect(() => {
        const video = videoRef.current!;
        const canvas = canvasRef.current!;
        const ctx = canvas.getContext('2d', { alpha: true, willReadFrequently: true })!;

        // Set up video element to use the MediaStream
        video.srcObject = stream;
        video.play();

        const computeAlpha = (r: number, g: number, b: number) => {
            const targetHue = 120; // Green hue in degrees
            const hueThreshold = 30; // Hue difference threshold for softness
            const saturationThreshold = 0.4; // Minimum saturation (0 to 1)
            const valueThreshold = 0.4; // Minimum brightness (0 to 1)
            const maxAlpha = 255;

            // Convert RGB to HSV
            const rgbToHsv = (r: number, g: number, b: number) => {
                r /= 255;
                g /= 255;
                b /= 255;
                const max = Math.max(r, g, b);
                const min = Math.min(r, g, b);
                const delta = max - min;

                let h = 0;
                let s = 0;
                const v = max;

                if (delta !== 0) {
                    s = delta / max;

                    if (max === r) {
                        h = ((g - b) / delta) % 6;
                    } else if (max === g) {
                        h = (b - r) / delta + 2;
                    } else {
                        h = (r - g) / delta + 4;
                    }

                    h *= 60;
                    if (h < 0) h += 360;
                }

                return { h, s, v };
            };

            const hsv = rgbToHsv(r, g, b);
            const hueDiff = Math.min(Math.abs(hsv.h - targetHue), 360 - Math.abs(hsv.h - targetHue));

            // Check if the pixel meets the criteria for chroma keying
            if (hueDiff < hueThreshold && hsv.s > saturationThreshold && hsv.v > valueThreshold) {
                // Compute alpha based on hue difference for soft edges
                const alpha = (hueDiff / hueThreshold) * maxAlpha;
                return alpha;
            } else {
                return maxAlpha; // Fully opaque
            }
        };

        const blurAlphaChannel = (alphaData: Uint8ClampedArray, width: number, height: number) => {
            const output = new Uint8ClampedArray(alphaData.length);
            const kernelSize = 3; // Adjust for more or less blur
            const edge = Math.floor(kernelSize / 2);

            for (let y = 0; y < height; y++) {
                for (let x = 0; x < width; x++) {
                    let sum = 0;
                    let count = 0;

                    for (let ky = -edge; ky <= edge; ky++) {
                        for (let kx = -edge; kx <= edge; kx++) {
                            const ny = y + ky;
                            const nx = x + kx;
                            if (nx >= 0 && nx < width && ny >= 0 && ny < height) {
                                const index = ny * width + nx;
                                sum += alphaData[index];
                                count++;
                            }
                        }
                    }

                    const index = y * width + x;
                    output[index] = sum / count;
                }
            }

            return output;
        };

        const render = () => {
            if (video.paused || video.ended) {
                return;
            }

            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            const frame = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const pixelData = frame.data;
            const length = pixelData.length / 4;
            const width = canvas.width;
            const height = canvas.height;

            // Create an array to store alpha values
            const alphaData = new Uint8ClampedArray(length);

            // First pass: Compute alpha values
            for (let i = 0; i < length; i++) {
                const offset = i * 4;
                const r = pixelData[offset];
                const g = pixelData[offset + 1];
                const b = pixelData[offset + 2];

                // Compute alpha
                const alpha = computeAlpha(r, g, b);

                // Store alpha value
                alphaData[i] = alpha;
            }

            // Apply blur to the alpha channel
            const blurredAlphaData = blurAlphaChannel(alphaData, width, height);

            // Second pass: Update pixel data with blurred alpha and apply spill suppression
            for (let i = 0; i < length; i++) {
                const offset = i * 4;
                const alpha = blurredAlphaData[i];

                // Temporal smoothing
                if (previousFrame.current) {
                    const prevAlpha = previousFrame.current.data[offset + 3];
                    const smoothingFactor = 0.7; // Adjust between 0 (no smoothing) and 1 (full smoothing)
                    pixelData[offset + 3] = alpha * (1 - smoothingFactor) + prevAlpha * smoothingFactor;
                } else {
                    pixelData[offset + 3] = alpha;
                }

                // Implement spill suppression for pixels with partial transparency
                if (alpha < 255) {
                    const greenSpill = pixelData[offset + 1] - Math.max(pixelData[offset], pixelData[offset + 2]);
                    const reductionFactor = (255 - alpha) / 255;
                    pixelData[offset + 1] -= greenSpill * reductionFactor;
                }
            }

            // Store the current frame for the next iteration
            previousFrame.current = new ImageData(new Uint8ClampedArray(pixelData), frame.width, frame.height);

            ctx.putImageData(frame, 0, 0);

            animationFrameId.current = requestAnimationFrame(render);
        };

        // When video metadata is loaded, set canvas dimensions
        const handleLoadedMetadata = () => {
            // Get the actual dimensions of the video
            const width = video.videoWidth;
            const height = video.videoHeight;

            // Set canvas dimensions to match video dimensions
            canvas.width = width;
            canvas.height = height;

            // Start the rendering loop
            render();
        };

        video.addEventListener('loadedmetadata', handleLoadedMetadata);

        return () => {
            // Clean up: Stop the animation loop and event listeners
            video.pause();
            video.removeEventListener('loadedmetadata', handleLoadedMetadata);
            if (animationFrameId.current) {
                cancelAnimationFrame(animationFrameId.current);
            }
        };
    }, [stream]);

    return (
        <div style={{ zIndex: 2 }}>
            {/* Hidden video element */}
            <video ref={videoRef} style={{ display: 'none' }} />

            {/* Canvas where the processed video is displayed */}
            <canvas ref={canvasRef} style={{ backgroundColor: 'transparent' }} />

            {/* You can add Material-UI components or styles here */}
        </div>
    );
};

export default ChromaKeyVideo;

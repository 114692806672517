import * as React from 'react';
import { Chatbot } from '../../../model/Chatbot';
import {
    Alert, Box, Checkbox,
    Dialog,
    DialogActions,
    DialogContent, Fab, FormControlLabel,
    Grid,
    IconButton, LinearProgress,
    Paper,
    TextField,
    Typography
} from '@mui/material';
import DialogHeader from '../../../shared/components/dialogs/DialogHeader';
import { useChatbotApiClient } from '../../../clients/ChatbotApiClient';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import DisabledToolTip from '../../../shared/components/DisabledToolTip';
import { useTranslation } from 'react-i18next';
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {useTheme} from "@mui/material/styles";
import { useHelperBotContext } from '../../../shared/contexts/HelperBotContext';
import {BsFileEarmark, BsFileImage, BsFilePdf, BsFileText} from "react-icons/bs";

export interface IAddWebsiteModalProps {
    chatbot: Chatbot
    open?: boolean
    onClose?(): void
}

const AddWebsiteModal: React.FunctionComponent<IAddWebsiteModalProps> = (props: IAddWebsiteModalProps) => {

    const theme = useTheme()
    const { t } = useTranslation()
    // eslint-disable-next-line
    const {questionToAsk, setQuestionToAsk } = useHelperBotContext()

    const chatbotClient = useChatbotApiClient({ preventNotification: true })

    const [rootURL, setRootURL] = useState<string>("https://www.")
    const [subdomainsToExclude, setSubdomainsToExclude] = useState<string>("")
    const [classNamesToInclude, setClassNamesToInclude] = useState<string>("*")
    const [classNamesToExclude, setClassNamesToExclude] = useState<string>("")
    const [renderPage, setRenderPage] = useState<boolean>(false)
    const [followSameOriginLinksOnly, setFollowSameOriginLinksOnly] = useState<boolean>(true)
    const [scrapeSubdomains, setScrapeSubdomains] = useState<boolean>(true)
    const [linkDepth, setLinkDepth] = useState<number>(1)
    const [rootScraping, setRootScraping] = useState<boolean>(false)

    const [htmlPageCount, setHtmlPageCount] = useState(0)
    const [pdfPageCount, setPdfPageCount] = useState(0)
    const [imgsCount, setImgsCount] = useState(0)
    const [otherContentCount, setOtherContentCount] = useState(0)

    const [pageError, setPageError] = useState<string>()
    
    const startScraping = () => {
        setRootScraping(true)
        chatbotClient.scrapePage({
                url: rootURL,
                chatbotId: props.chatbot.id,
                linkDepth: linkDepth,
                followSameOriginLinksOnly: followSameOriginLinksOnly,
                subdomainsToExclude: subdomainsToExclude.split(","),
                classnamesToInclude: classNamesToInclude,
                classnamesToExclude: classNamesToExclude,
                scrapeSubdomains: scrapeSubdomains,
                renderPage: renderPage,
        })
            .then((scraperID: string) => {

                chatbotClient.subsribeToScrapeStatus(scraperID,
                    (event: string, event_type: string) => {
                        const res = JSON.parse(event)
                        setHtmlPageCount(res.html)
                        setPdfPageCount(res.pdf)
                        setImgsCount(res.img)
                        setOtherContentCount(res.tasks)
                    },
                    (lastChunkReceived: string) => {
                        debugger
                    }
                )                
                // setRootScraping(false)
            })
    }



    const onClose = () => {
        setPageError(undefined)
        props.onClose && props.onClose()
    }

    return (
        <>
            <Dialog
                open={!!(props.open)}
                PaperProps={{ sx: { backgroundColor: "white" } }}
                disableEnforceFocus
                fullWidth
                maxWidth={'lg'}
                scroll="paper"
                onClose={onClose}
            >
                <DialogHeader
                    headline={t("Add Website to Chatbot")}
                    closeDialog={onClose} />
                <DialogContent>
                    <Grid container spacing={2}>
                        {pageError &&
                            <Grid item xs={12} container justifyContent='center'>
                                <Alert severity="error">
                                    {pageError}
                                </Alert>
                            </Grid>
                        }
                        <Grid item xs={12} container spacing={2} alignItems='center' sx={{marginTop: "10px"}}>
                            <Grid item xs={8}>
                                <TextField
                                    label={"URL"}
                                    variant="outlined"
                                    fullWidth
                                    value={rootURL}
                                    onChange={(e) => setRootURL(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <TextField
                                    type={"number"}
                                    label={"Depth (0-6)"}
                                    variant="outlined"
                                    fullWidth
                                    value={linkDepth}
                                    onChange={(e) => setLinkDepth(+e.target.value)}
                                    InputProps={{endAdornment: 
                                            <IconButton size={"small"} sx={{margin: "0px", padding: "0 0 7px 0", color: theme.palette.primary.main}} 
                                                        onClick={()=>setQuestionToAsk(t("What does the parameter link-depth do in web-scraping a website?"))}>
                                                <HelpOutlineOutlinedIcon sx={{width: "12px"}}/>
                                            </IconButton>}}
                                />
                            </Grid>
                            <Grid item xs={3} container >
                                <FormControlLabel control={<Checkbox checked={renderPage}
                                                                     onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRenderPage(event.target.checked)} />}
                                                  label={
                                                      <Box display="flex" alignItems="center">
                                                          Render Page
                                                          <IconButton
                                                              aria-label="help"
                                                              onClick={()=>setQuestionToAsk(t("What does the parameter 'render page' do in web-scraping a website?"))}
                                                              size="small"
                                                          >
                                                              <HelpOutlineOutlinedIcon sx={{width: "12px"}} />
                                                          </IconButton>
                                                      </Box>
                                                  }
                                />
                            </Grid>

                            {/*--------------*/}

                            <Grid item xs={9}>
                                <TextField
                                    label={"Subdomains to exclude (eg https://excl.domain.com)"}
                                    variant="outlined"
                                    fullWidth
                                    value={subdomainsToExclude}
                                    onChange={(e) => setSubdomainsToExclude(e.target.value)}
                                    InputProps={{endAdornment:
                                            <IconButton size={"small"} sx={{margin: "0px", padding: "0 0 7px 0", color: theme.palette.primary.main}}
                                                        onClick={()=>setQuestionToAsk(t("What does the parameter link-depth do in web-scraping a website?"))}>
                                                <HelpOutlineOutlinedIcon sx={{width: "12px"}}/>
                                            </IconButton>}}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel control={<Checkbox checked={followSameOriginLinksOnly}
                                                                     onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFollowSameOriginLinksOnly(event.target.checked)} />}
                                                  label={
                                                      <Box display="flex" alignItems="center">
                                                          Stay in the given domain
                                                          <IconButton
                                                              aria-label="help"
                                                              onClick={()=>setQuestionToAsk(t("What does the parameter 'Stay in the given domain' do in web-scraping a website?"))}
                                                              size="small"
                                                          >
                                                              <HelpOutlineOutlinedIcon sx={{width: "12px"}} />
                                                          </IconButton>
                                                      </Box>
                                                  }
                                />
                            </Grid>

                            {/*--------------*/}
                            
                            <Grid item xs={5}>
                                <TextField
                                    label={"Content: Class-names to include (default: *)"}
                                    variant="outlined"
                                    fullWidth
                                    value={classNamesToInclude}
                                    onChange={(e) => setClassNamesToInclude(e.target.value)}
                                    InputProps={{endAdornment:
                                            <IconButton size={"small"} sx={{margin: "0px", padding: "0 0 7px 0", color: theme.palette.primary.main}}
                                                        onClick={()=>setQuestionToAsk(t("What does the parameter link-depth do in web-scraping a website?"))}>
                                                <HelpOutlineOutlinedIcon sx={{width: "12px"}}/>
                                            </IconButton>}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label={"Links: Class-names to exclude (default: *)"}
                                    variant="outlined"
                                    fullWidth
                                    value={classNamesToExclude}
                                    onChange={(e) => setClassNamesToExclude(e.target.value)}
                                    InputProps={{endAdornment:
                                            <IconButton size={"small"} sx={{margin: "0px", padding: "0 0 7px 0", color: theme.palette.primary.main}}
                                                        onClick={()=>setQuestionToAsk(t("What does the parameter link-depth do in web-scraping a website?"))}>
                                                <HelpOutlineOutlinedIcon sx={{width: "12px"}}/>
                                            </IconButton>}}
                                />
                            </Grid>
                            <Grid item xs={3} container justifyContent="flex-end">
                                <FormControlLabel control={<Checkbox checked={scrapeSubdomains}
                                                                     onChange={(event: React.ChangeEvent<HTMLInputElement>) => setScrapeSubdomains(event.target.checked)} />}
                                                  label="Scrape all subdomains (*.mydomain.com)" />
                            </Grid>

                            {/*--------------*/}

                            
                            <Grid item xs={12} container justifyContent="center">
                                <Grid item>
                                    <Fab onClick={() => startScraping()} color="primary">
                                        Scrape
                                    </Fab>
                                </Grid>
                            </Grid>
                        </Grid>

                        {rootScraping && 
                            <>
                                <Grid item xs={12} container spacing={2} alignItems='center' sx={{marginTop: "10px"}}>
                                    <LinearProgress sx={{width: '50%', margin: '0 auto'}}/>
                                </Grid>

                                {/* status info about number of scraped elements so far */}
                                <Grid item xs={12} >
                                    <Grid container sx={{ paddingBottom: "1em", overflow: 'auto' }} spacing={1} >
                                        <Grid item xs={3}>
                                            <Paper sx={{ display: "flex", alignItems: "center", padding: "10px"}}>
                                                <Grid item>
                                                    <Typography sx={{ fontSize: "50px", color: "gray" }}>
                                                        <BsFileText fontSize={"inherit"}/>
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography sx={{ display: "flex", alignItems: "center", fontSize: "30px", color: "gray", marginLeft: "20px" }}>
                                                        {htmlPageCount}
                                                    </Typography>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Paper sx={{ display: "flex", alignItems: "center", padding: "10px"}}>
                                                <Grid item>
                                                    <Typography sx={{ fontSize: "50px", color: "gray" }}>
                                                        <BsFilePdf fontSize={"inherit"}/>
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography sx={{ display: "flex", alignItems: "center", fontSize: "30px", color: "gray", marginLeft: "20px" }}>
                                                        {pdfPageCount}
                                                    </Typography>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Paper sx={{ display: "flex", alignItems: "center", padding: "10px"}}>
                                                <Grid item>
                                                    <Typography sx={{ fontSize: "50px", color: "gray" }}>
                                                        <BsFileImage fontSize={"inherit"}/>
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography sx={{ display: "flex", alignItems: "center", fontSize: "30px", color: "gray", marginLeft: "20px" }}>
                                                        {imgsCount}
                                                    </Typography>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Paper sx={{ display: "flex", alignItems: "center", padding: "10px"}}>
                                                <Grid item>
                                                    <Typography sx={{ fontSize: "50px", color: "gray" }}>
                                                        <BsFileEarmark fontSize={"inherit"}/>
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography sx={{ display: "flex", alignItems: "center", fontSize: "30px", color: "gray", marginLeft: "20px" }}>
                                                        {otherContentCount}
                                                    </Typography>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        
                        
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <DisabledToolTip tooltip={t('chatbot.uploadFiles.filesStillUploading')}>
                        <LoadingButton onClick={onClose} variant="contained">
                            {t('chatbot.uploadFiles.submit')}
                        </LoadingButton>
                    </DisabledToolTip>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AddWebsiteModal;
import { Box } from "@mui/material";
import React, { Suspense, useMemo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MyRoute, appRoutes } from "./AppRoutes";
import ProtectedContent from "../components/ProtectedContent";
import HeaderBar from "../components/HeaderBar";
import FooterBar from "../components/FooterBar";
import Page404 from "../components/Page404";
import GlobalLoader from "../components/GlobalLoader";
import { useUserContext } from "../contexts/UserContext";
import { useAuthApiClient } from "../../clients/AuthApiClient";
import { useUserApiClient } from "../../clients/UserApiClient";
import { User } from "../../model/User";
import NotificationHandler from "../components/notification/NotificationHandler";

export default function AppRouter() {
    const isMobile = React.useRef(window.innerWidth < 480);
    React.useEffect(() => {
        isMobile.current = (window.innerWidth < 480)
    })

    const RouteContent = (props: { route: MyRoute }) => {
        return (<>
            {!props.route.showContentOnly && <HeaderBar />}
            <Box component="div" sx={{ position: "relative", width: "100%", minHeight: "78vh", backgroundColor: "transparent", background: "transparent", }}>
                <Box component="div" sx={[(!props.route.showContentOnly && {
                    backgroundColor: "transparent", background: "transparent",
                    position: "relative",
                    marginTop: "12vh",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: isMobile.current ? "85%" : "90%",
                })]}>
                    <ProtectedContent protected={props.route.protected} roles={props.route.roles} >
                        {props.route.child}
                    </ProtectedContent>
                </Box>
            </Box>
            {!props.route.showContentOnly && <FooterBar />}
        </>)
    }

    const displayedRoutes: JSX.Element[] = useMemo(() => {
        const routes = []
        appRoutes.forEach(route => {
            routes.push(<Route key={route.path} path={route.path} element={<RouteContent route={route} />} />)
        })

        routes.push(<Route key="404" path="*" element={<RouteContent route={{
            child: < Page404 />,
            path: "*"
        }} />} />)
        return routes
    }, [])
    appRoutes.forEach(route => {
        displayedRoutes.push(<Route key={route.path} path={route.path} element={<RouteContent route={route} />} />)
    })
    displayedRoutes.push(<Route key="404" path="*" element={<RouteContent route={{
        child: < Page404 />,
        path: "*"
    }} />} />)


    const userContext = useUserContext()

    const authApiClient = useAuthApiClient()
    const userApiClient = useUserApiClient()

    const [authorizing, setAuthorizing] = React.useState(true)

    React.useEffect(() => {
        if (!userContext.isLoggedIn()) {
            userContext.setTokenFromCookies()
            authApiClient.isAuthorized()
                .then(() => {
                    return userApiClient.getCurrentUser()
                        .then((user: User) => {
                            userContext.setCurrentUser(user)
                        })
                })
                .catch(() => { })
                .finally(() => setAuthorizing(false))
        } else {
            setAuthorizing(false)
        }
    }, [authApiClient, userApiClient, userContext])

    const Content = useMemo(() => {
        if (authorizing) {
            return <GlobalLoader />
        }
        return (<>
            <BrowserRouter>
                <Suspense fallback={<GlobalLoader />}>
                    <Routes>
                        {displayedRoutes}
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </>)
    }, [authorizing, displayedRoutes])


    return (<>
        {Content}
        <NotificationHandler />
    </>)

}
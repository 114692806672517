import React from 'react';
import {Grid, Typography, Button} from '@mui/material';
import { Link } from 'react-router-dom'
import useHomeHeaderStyles from "../../shared/styles";
import { useUserContext } from '../../shared/contexts/UserContext';
import SmartToyIcon from '@mui/icons-material/SmartToyOutlined';
import CribIcon from '@mui/icons-material/CribOutlined';
import FastfoodIcon from '@mui/icons-material/FastfoodOutlined';
import {useTheme} from "@mui/material/styles";
import { useTranslation} from 'react-i18next';

const Dashboard = () => {
    const theme = useTheme();
    const classes = useHomeHeaderStyles();
    const { t } = useTranslation();
    const userCtx = useUserContext()

    return (
        <>
            <Grid container justifyContent="center" alignContent="center" direction="row" sx={{
                position: "relative",
                backgroundColor: "white",
                zIndex: 10,
                justifyContent: "space-evenly",
                alignItems: "center"
            }}>
                <Grid item xs={12} container justifyContent='center'>
                    <Typography variant="h3" fontWeight={700} sx={classes.title}>
                        {t('dashboard.welcome')}, {userCtx.user?.username ?? 'User'}!
                    </Typography>
                </Grid>
            </Grid>
            <Grid></Grid>
            <Grid item xs={12} container justifyContent="center" spacing={20}>
                
                <>
                    <Grid item xs={12} sm={6} md={3} lg={3} container justifyContent="center">
                        <Button
                            variant="outlined"
                            size="large"
                            component={Link}
                            to="/chatBots"
                            sx={{ padding: "1em", fontSize: "20pt", borderRadius: "10px", boxShadow: "none", backgroundColor: "white" }}
                        >
                            <Grid container justifyContent="center" alignItems="center" textAlign="center" spacing={2}>
                                <Grid item xs={12}>
                                    <SmartToyIcon sx={{ fontSize: 100, color: theme.palette.primary.main }} height="180px" />
                                </Grid>
                                <Grid item xs={12} sx={{ color: theme.palette.primary.main}}>
                                    {t('dashboard.myChatbots')}
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} container justifyContent="center">
                        <Button
                            variant="outlined"
                            size="large"
                            component={Link}
                            to="/create-bot"
                            sx={{ padding: "1em", fontSize: "20pt", borderRadius: "10px", boxShadow: "none", backgroundColor: "white" }}
                        >
                            <Grid container justifyContent="center" alignItems="center" textAlign="center" spacing={2}>
                                <Grid item xs={12}>
                                    <CribIcon sx={{ fontSize: 100, color: theme.palette.primary.main }} height="180px" />
                                </Grid>
                                <Grid item xs={12} sx={{ color: theme.palette.primary.main}}>
                                    {t('dashboard.newChatbot')}
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} container justifyContent="center">
                        <Button
                            variant="outlined"
                            size="large"
                            component={Link}
                            to="/instantBot"
                            sx={{ padding: "1em", fontSize: "20pt", borderRadius: "10px", boxShadow: "none", backgroundColor: "white" }}
                        >
                            <Grid container justifyContent="center" alignItems="center" textAlign="center" spacing={2}>
                                <Grid item xs={12}>
                                    <FastfoodIcon sx={{ fontSize: 100, color: theme.palette.primary.main }} height="180px" />
                                </Grid>
                                <Grid item xs={12} sx={{ color: theme.palette.primary.main}}>
                                    {t('dashboard.instantChatbot')}
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                </>
                
            </Grid>
        </>)
        ;
};

export default Dashboard;
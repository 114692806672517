import React, { useState, useEffect } from 'react';

export const funnyW8Messages = [
    "Trainiere neuronales Netz… 🧠",
    "Lade künstliche Synapsen… ⚡",
    "Optimiere die Algorithmen… 🧮",
    "Kalibriere Robo-Gehirn… 🤖",
    "Generiere KI-Intuition… 🔮",
    "Initiere maschinelles Lernen… 📈",
    "Simuliere neuronale Verbindungen… 🌐",
    "Verarbeite Big Data… 📊",
    "Aktiviere KI-Unterbewusstsein… 💭",
    "Lerne menschliches Verhalten… 👥",
    "Vermesse das Ulmer Münster… 🏰",
    "Befülle Donau mit Daten… 🌊",
    "Suche nach Spatzen in Ulm… 🐦",
    "Kalibriere Münster Turm… 🌆",
    "Aktiviere Ulmer Wissenschaftsstadt… 🧬",
    "Lade Ulmer Flussgeschichten… 📜",
    "Synchronisiere mit Ulmer Einstein… 🧠",
    "Starte Schwörmontag-Protokoll… 🎉",
    "Berechne Ulmer Einstein-Marathon… 🏃️",
    "Aktualisiere Ulmer Universitätsnetz… 🎓",
    "Initialisiere Warp-Antrieb… 🛸",
    "Generiere molare Grobstruktur… ⚛️",
    "Synchronisiere Flux-Kompensator… 🔄",
    "Kalibriere Schrödingers Katze… 🐱📦",
    "Erzeuge digitale Kauderwelsch-Matrix… 🧩",
    "Aktiviere photonische Übertragung… 💡",
    "Aufwärmen der Quantenbits… 🕹️",
    "Kompatibilitätsprüfung der Zeitreisefunktion… ⏳",
    "Transdimensionale Datenverdichtung läuft… 📡",
    "Virtuelles Kaffeeholen für den Server… ☕",
    "Starte Subraum-Kommunikation… 📡",
    "Zuführen von positronischen Neuronen… 🤖",
    "Berechne hyperdimensionale Koordinaten… 🌌",
    "Stimme Tachyonen-Emitter ab… 🔧",
    "Initialisiere Antimaterie-Kammer… 🚀",
    "Entwirre Quanten-Verschränkungen… 🌀",
    "Lade Kosmische Hintergrundstrahlung… 🌠",
    "Simuliere Gravitationswellen… 🌊",
    "Dekodiere außerirdische Signale… 👽",
    "Kompiliere Traktorstrahl-Bibliothek… 🌟",
    "Aktiviere Nanobot-Schwarm… 🦠",
    "Konfiguriere Holodeck-Parameter… 🎮",
    "Transformiere Dimensionsdaten… 🌀",
    "Synchronisiere interstellare Uhren… ⏱️",
    "Generiere Multiversen-Hash… 🔍",
    "Entfessle dunkle Materie… 🌑",
    "Analyziere Wurmloch-Stabilität… 🚪",
    "Lade kybernetische Updates… 💾",
    "Entschlüssele Sternenkarte… 🗺️",
    "Initialisiere Holo-Transmitter… 📺",
    "Feinabstimmung des Phaser-Arrays… 🔫",
    "Moduliere Subraum-Frequenzen… 📡",
    "Kompiliere galaktische Datenbank… 📚",
    "Starten der Zeitreise-Algorithmen… ⏳",
    "Verbinde neuronales Netzwerk… 🧠",
    "Erzeuge intergalaktischen Lesevorgang… 📖",
    "Aktiviere Laser-Düsen… 🔥",
    "Kalibriere kosmische Sensoren… 🛰️",
    "Lade Raum-Zeit-Koordinaten… 🕰️",
    "Starte Astro-Initiationsprotokoll… 🚀",
    "Repariere defektes Byte… 🛠️",
    "Starte Datenbank-Dämon… 📚",
    "Lade Treiber für den Kaffeeautomaten… ☕",
    "Initialisiere Cyberspace… 🌐",
    "Komprimiere Bits und Bytes… 🗜️",
    "Aktiviere Firewall-Schilde… 🔥",
    "Konfiguriere Netzwerkprotokolle… 📡",
    "Implementiere neue Algorithmen… 🧩",
    "Pinge die Matrix… 🏓",
    "Synchronisiere Server-Farmen… 🌾",
    "Initialisiere Debugging-Modus… 🐞",
    "Konvertiere Hexadezimal zu Binär… 🔢",
    "Upgrade auf das nächste Level… ⏫",
    "Backup der virtuellen Realität… 💾",
    "Booten des Systems… 🚀",
    "Verhandle Koalitionsvertrag… 📝",
    "Aktualisiere Wahlversprechen… 📈",
    "Scanne politische Landschaft… 🌍",
    "Lobbyisten umschiffen… 🚢",
    "Debattiere im Parlament… 🏛️",
    "Kalibriere Meinungsumfragen… 📊",
    "Erstelle Gesetzesvorlage… 📜",
    "Verkünde Pressekonferenz… 🎤",
    "Warte auf Zustimmung des Senats… 🗳️",
    "Simuliere diplomatische Verhandlungen… 🤝",
    "Repariere politische Brücken… 🌉",
    "Entwirre Bürokratie-Dschungel… 🏞️",
    "Planung der nächsten Kampagne… 🎟️",
    "Analysiere Wahlkreis… 📋",
    "Erstelle Haushaltsplan… 💰",
    "Starte Petitionslauf… 🖋️",
    "Aktiviere Transparenzmodus… 🔍",
    "Kalibriere politische Kompasse… 🧭",
    "Debattiere in der Talkshow… 📺",
    "Warte auf diplomatische Post… ✉️",
    "Aufwärmen der Athleten… 🏃️",
    "Kalibriere Torlinie… ⚽",
    "Stimme den Schiedsrichter ab… 🕴️",
    "Trainiere KI-Trainingsplan… 🏋️",
    "Veranstalte Taktikbesprechung… 📋",
    "Bereite Halbzeitpause vor… 🍊",
    "Aktiviere Jubelmodus… 🎉",
    "Starte Fotofinish-Kamera… 📸",
    "Synchronisiere Scoreboard… 🏅",
    "Aufbau des Spielfelds… 🏟️",
    "Packe Koffer… 🎒",
    "Reserviere Strandliegen… 🏖️",
    "Synchronisiere Reiseführer… 📚",
    "Aktiviere Sonnencreme-Modus… 🌞",
    "Kalibriere Kompass… 🧭",
    "Erkunde Sehenswürdigkeiten… 🗺️",
    "Buchung des Hotels… 🏨",
    "Aufbau der Sandburg… 🏰",
    "Starte Stadtrundfahrt… 🚌",
    "Bereite Souvenirs vor… 🎁",
    "Besuche Ulmer Volksfest… 🎡",
    "Simuliere Fischerstechen… 🐟",
    "Aktiviere Ulmer Nachtleben… 🌃",
    "Aktiviere Rasenheizung im Stadion… 🔥",
    "Baue Floß für's Nabada… 🚢",

];

interface W8MsgProps {
    w8Messages: string[]
}

const RandomFunnyLoadingMessages: React.FC<W8MsgProps> = (props: W8MsgProps) => {
    

    const [currentMessageIndex, setCurrentMessageIndex] = useState(Math.floor(Math.random() * props.w8Messages.length));

    useEffect(() => {
        const intervalId = setInterval(() => {
            const randomIndex = Math.floor(Math.random() * props.w8Messages.length);
            setCurrentMessageIndex(randomIndex);
        }, 3000);

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [props.w8Messages.length]);

    return (
        <div style={{fontSize: '10px'}}>
            <p>{props.w8Messages[currentMessageIndex]}</p>
        </div>
    );
};

export default RandomFunnyLoadingMessages;
import { Alert, Fab, Grid, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import Headline from '../../shared/components/Headline';
import { useCallback, useEffect, useState } from 'react';
import { ChatbotPagination } from '../../model/Chatbot';
import { useChatbotApiClient } from '../../clients/ChatbotApiClient';
import { Link } from 'react-router-dom';
import { ErrorResponse } from '../../clients/error/ErrorResponse';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ChatbotTableRow from './components/ChatbotTableRow';

export interface IChatBotsProps {
}

const ChatBots: React.FunctionComponent<IChatBotsProps> = (props: IChatBotsProps) => {

    const chatbotClient = useChatbotApiClient()
    const { t } = useTranslation()

    const [pageError, setPageError] = useState<string>()

    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(5)

    const [chatbots, setChatbots] = useState<ChatbotPagination>()

    const fetchChatbots = useCallback(() => {
        setChatbots(undefined)
        chatbotClient.getAllChatbots(page, pageSize)
            .then(setChatbots)
            .catch((err: ErrorResponse) => { setPageError(err.message) })
    }, [chatbotClient, page, pageSize])

    useEffect(() => {
        fetchChatbots()
    }, [fetchChatbots])

    return (
        <>
            <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                    <Headline title={t('chatbots.myChatbots')} />
                </Grid>

                {pageError &&
                    <Grid item xs={12} container justifyContent='center'>
                        <Alert severity="error">
                            {pageError}
                        </Alert>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Paper>
                        <Grid container sx={{ padding: "1em" }}>
                            <Grid item xs={12} container justifyContent="flex-end">
                                <Grid item>
                                    <Tooltip title={t('chatbots.newChatbot')}>
                                        <Fab color="primary" component={Link} to="/create-bot">
                                            <Add />
                                        </Fab>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container spacing={3}>
                                <Grid item xs={12}>
                                    {chatbots ?
                                        <Table size='small' >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width="35%">{t('chatbots.name')}</TableCell>
                                                    <TableCell width="15%" align="right"> {t('chatbots.numTrainingDocs')}</TableCell>
                                                    <TableCell width="15%" align="right"> {t('chatbots.numQueries')}</TableCell>
                                                    <TableCell width="10%" align="center"> {t('chatbots.trainingStatus')}</TableCell>
                                                    <TableCell width="25%"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {chatbots.chatbots.map(bot => <ChatbotTableRow chatbot={bot} onDelete={() => fetchChatbots()} />)}
                                                {chatbots.chatbots.length === 0 && <TableRow>
                                                    <TableCell colSpan={5}>
                                                        <Typography variant="body1" align="center">
                                                            <i>{t('chatbots.noChatbotCreated')}</i>
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow  >
                                                    <TablePagination
                                                        labelRowsPerPage={t('common.entriesPerPage')}
                                                        rowsPerPageOptions={[3, 5, 10]}
                                                        count={chatbots.totalResults}
                                                        rowsPerPage={pageSize}
                                                        page={page}
                                                        onPageChange={(e, newPage) => {
                                                            setPage(newPage)
                                                        }}
                                                        onRowsPerPageChange={(e) => {
                                                            setPageSize(+e.target.value)
                                                        }}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                        :
                                        <Stack>
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                        </Stack>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default ChatBots;
import { Grid, Button, Typography, TextField, Dialog, DialogActions, DialogContent } from '@mui/material';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DialogHeader from './DialogHeader';
import { Trans } from 'react-i18next';

export interface IConfirmDeletionDialogProps {
    open: boolean
    onClose(): void
    onConfirm?(): void
    confirmQuestion: string
    confirmPhrase?: string
    href?: string
    severity: "error" | "info"
}

const ConfirmDialog: React.FunctionComponent<
    React.PropsWithChildren<IConfirmDeletionDialogProps>
> = (props: React.PropsWithChildren<IConfirmDeletionDialogProps>) => {

    const [confirmText, setConfirmText] = React.useState("")

    return (
        <>
            <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
                <DialogHeader
                    closeDialog={() => props.onClose()}
                    headline={props.confirmQuestion}
                />
                <DialogContent dividers>
                    <Grid container spacing={3}>
                        {props.confirmPhrase && <>
                            <Grid item xs={12} container alignContent="center" justifyContent="center">
                                <Typography align='center' variant="body1"><Trans i18nKey='common.confirmPhrase' values={{ confirmPhrase: props.confirmPhrase }} /></Typography>
                            </Grid>
                            <Grid item xs={12} container alignContent="center" justifyContent="center">
                                <TextField fullWidth value={confirmText} onChange={(e) => setConfirmText(e.target.value)} />
                            </Grid>
                        </>}
                        {props.severity === "info" &&
                            <Grid item xs={12} container justifyContent="left">
                                <Typography>{props.confirmQuestion}</Typography>
                                {props.children}
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions sx={{
                    padding: "12px",
                    justifyContent: "space-between"
                }}>
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        onClick={() => {
                            props.onClose()
                        }}
                    >
                        <Trans i18nKey='common.cancel' />
                    </Button>
                    {props.href ? <Button
                        component="a"
                        href={props.href}
                        sx={{ textDecoration: "none" }}
                        disabled={props.confirmPhrase !== undefined && props.confirmPhrase !== confirmText}
                        color={props.severity === "error" ? "error" : "info"}
                        startIcon={<CheckIcon />}
                        onClick={() => {
                            props.onConfirm && props.onConfirm()
                            props.onClose()
                        }} variant="contained">
                        <Trans i18nKey='common.confirm' />
                    </Button> :
                        <Button
                            disabled={props.confirmPhrase !== undefined && props.confirmPhrase !== confirmText}
                            color={props.severity === "error" ? "error" : "info"}
                            startIcon={<CheckIcon />}
                            onClick={() => {
                                props.onConfirm && props.onConfirm()
                                props.onClose()
                            }} variant="contained">
                            <Trans i18nKey='common.confirm' />
                        </Button>}
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ConfirmDialog;
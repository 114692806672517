// ProtocolApp.tsx
import React, { useState } from 'react';
import {Box, Fab, TextField} from '@mui/material';
import Dictaphone2 from "../../../shared/components/Dictaphone2";

const ProtocolApp: React.FC = () => {
    const [transcription, setTranscription] = useState<string>('');

    const handleTranscription = (newTranscription: string, final: boolean) => {
        if (final) {
            setTranscription(newTranscription);   
        } else {
            setTranscription(prev => prev + newTranscription);    
        }
    };

    return (
        <Box sx={{ maxWidth: 600, margin: '0 auto', padding: 2 }}>
            {/* Recorder Component */}
            <center>
                <Fab>
                    <Dictaphone2 onTranscription={handleTranscription} 
                                 iconColor={'green'} iconColorRecording={'red'}/>
                </Fab>
            </center>

            {/* Transcription Display */}
            <TextField
                label="Transcription"
                multiline
                rows={6}
                value={transcription}
                variant="outlined"
                fullWidth
                InputProps={{
                    readOnly: true,
                }}
                sx={{ marginTop: 2 }}
            />
        </Box>
    );
};

export default ProtocolApp;

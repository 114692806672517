import { DialogTitle, Grid, IconButton, SxProps } from '@mui/material';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Headline from '../Headline';

export interface IDialogHeaderProps {
    headline: string
    styling?: SxProps
    closeDialog?(): void
    actionItems?: JSX.Element
}

const DialogHeader: React.FunctionComponent<IDialogHeaderProps> = (props: IDialogHeaderProps) => {
    return (
        <DialogTitle sx={props.styling}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={8}>
                    <Headline size='sub' title={props.headline} />
                </Grid>
                <Grid item xs={4} spacing={1} container justifyContent="flex-end" alignItems="center">
                    {props.actionItems}
                    <Grid item>
                        <IconButton
                            onClick={props.closeDialog}
                            color="inherit"
                            sx={{ fontSize: "22px" }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </DialogTitle>
    );
}

export default DialogHeader;
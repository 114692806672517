import * as CryptoJS from "crypto-js";

export const calculateMd5Hash = async (f: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            const result: ArrayBuffer = fileReader.result! as ArrayBuffer;
            const wordArray = CryptoJS.lib.WordArray.create(new Uint8Array(result) as any)
            const hash = CryptoJS.MD5(wordArray).toString()
            console.log(result)
            console.log(hash)
            return resolve(hash)
        };
        fileReader.readAsArrayBuffer(f);
    })
}
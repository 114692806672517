import React, { useEffect, useState } from "react";
import { Typography, Grid, Card, CardContent, Divider } from "@mui/material";
import { useApiStripeClient } from "../../clients/ApiStripeClient";
import { Product } from "../../model/Product";
import { Price } from "../../model/Price";
import { useUserContext } from "../../shared/contexts/UserContext";
import { Subscription } from "../../model/Subscription";
import LoginDialog from "./priceComponents/LoginDialog";
import { useCustomThemeProps } from "./useCustomThemeProps";
import { useTranslation } from "react-i18next";
import CurrencyToggleButtonGroup from "./priceComponents/CurrencyToggleButtonGroup";
import ProductDescriptionList from "./priceComponents/ProductDescriptionList";
import ProductSubscriptionButton from "./priceComponents/ProductSubscriptionButton";
import PriceList from "./priceComponents/PriceList";

function PriceComponent() {
    const stripeClient = useApiStripeClient();

    const userContext = useUserContext();

    const customTheme = useCustomThemeProps();

    const { t } = useTranslation();

    const [products, setProducts] = useState<{ [key: string]: Product }>({});
    const [prices, setPrices] = useState<{ [key: string]: Price }>({});
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

    const [currency, setCurrency] = useState<string>("eur");

    const [isLoading, setIsLoading] = useState(true);

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [loginCheckCounter, setLoginCheckCounter] = useState(0);

    const changeCurrency = (
        _event: React.MouseEvent<HTMLElement>,
        newCurrency: string | null
    ) => {
        if (newCurrency !== null) {
            setCurrency(newCurrency);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        stripeClient
            .getProductsSorted()
            .then((response) => {
                console.log(response);
                setProducts(response);
            })
            .catch(() => {});

        stripeClient
            .getPrices()
            .then((response) => setPrices(response))
            .catch(() => {});

        if (userContext.user?.stripeCustomerId) {
            stripeClient
                .getActiveSubscriptions(userContext.user.stripeCustomerId)
                .then((response) => {
                    setSubscriptions(response);
                    setCurrency(response[0].currency);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [
        stripeClient,
        userContext.user?.stripeCustomerId,
        loginCheckCounter,
        userContext.user,
    ]);

    const subscribe = (product: Product) => {
        if (!userContext.isLoggedIn()) {
            handleOpenDialog();
            return;
        }

        if (subscriptions.length > 0 && userContext.user?.stripeCustomerId) {
            setIsLoading(true);
            stripeClient
                .customerPortalUrl(userContext.user.stripeCustomerId, "", "")
                .then((url) => window.open(url, "_blank"))
                .catch(() => {})
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            if (userContext.user?.stripeCustomerId) {
                setIsLoading(true);
                stripeClient
                    .createCheckoutSession(
                        userContext.user!.email,
                        product.defaultPrice,
                        userContext.user.stripeCustomerId
                    )
                    .then((url) => window.open(url, "_blank"))
                    .catch(() => {});
            } else {
                stripeClient
                    .createCheckoutSession(
                        userContext.user!.email,
                        product.defaultPrice,
                        ""
                    )
                    .then((url) => window.open(url, "_blank"))
                    .catch(() => {});
            }
        }
    };

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setLoginCheckCounter((prev) => prev + 1);
    };

    return (
        <>
            <Grid
                container
                spacing={2}
                justifyContent="center"
                sx={customTheme.grid}
            >
                {Object.values(products).map((product) => {
                    // Filter prices to include only those that match the product and currency
                    const filteredPrices = Object.values(prices).filter(
                        (price) =>
                            price.product === product.id &&
                            price.currency === currency
                    );

                    // Check if there are any matching prices before rendering the product
                    if (filteredPrices.length > 0) {
                        return (
                            <Grid
                                item
                                key={product.id}
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                            >
                                <Card
                                    sx={{
                                        border:
                                            subscriptions[0]?.productName ===
                                            product.name
                                                ? "2px solid #7A9D54"
                                                : "",
                                        minWidth:
                                            subscriptions[0]?.productName ===
                                            product.name
                                                ? "275"
                                                : "0",
                                    }}
                                >
                                    <CardContent>
                                        <Typography variant="h5" align="center">
                                            {product.name}
                                        </Typography>
                                        <PriceList
                                            filteredPrices={filteredPrices}
                                            currency={currency}
                                            t={t}
                                        />
                                        <CurrencyToggleButtonGroup
                                            currency={currency}
                                            changeCurrency={changeCurrency}
                                        />

                                        <Divider
                                            variant="middle"
                                            sx={customTheme.dividerMarginTop}
                                        />

                                        <ProductDescriptionList
                                            product={product}
                                        />

                                        <Divider
                                            variant="middle"
                                            sx={{
                                                ...customTheme.dividerMarginBottom,
                                            }}
                                        />
                                        <ProductSubscriptionButton
                                            isLoading={isLoading}
                                            product={product}
                                            subscriptions={subscriptions}
                                            subscribe={subscribe}
                                        />

                                        <LoginDialog
                                            open={isDialogOpen}
                                            onClose={handleCloseDialog}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    } else {
                        return null;
                    }
                })}
            </Grid>
        </>
    );
}

export default PriceComponent;

import ApiClient, { ClientOptions } from "./ApiClient";
import { createContext, useContext } from "react";
import { useNotificationContext } from "../shared/contexts/NotificationContext";
import {
    Chatbot,
    ChatbotPagination,
    ChatbotSettings,
    FeedbackContent,
    TrainingSettings, TranslationRequest
} from "../model/Chatbot";
import { FileEntity } from "../model/FileEntity";
import { ChatRequest } from "../model/ChatResponse";
import {ScrapeRequest} from "../model/ScrapeRequest";

export default class ChatbotApiClient extends ApiClient {
    public getAllChatbots = async (page = 0, pageSize = 20, options?: ClientOptions): Promise<ChatbotPagination> => {
        return this.get(`/chatbot/all?page=${page}&pageSize=${pageSize}`, options);
    }

    public createChatbot = async (name: string, description?: string, options?: ClientOptions): Promise<Chatbot> => {
        return this.post('/chatbot', { body: { name, description }, ...options });
    }

    public getChatbot = async (chatbotId: string, options?: ClientOptions): Promise<Chatbot> => {
        return this.get(`/chatbot/${chatbotId}`, options);
    }

    public deleteChatbot = async (chatbotId: string, options?: ClientOptions): Promise<Chatbot> => {
        return this.delete(`/chatbot/${chatbotId}`, options);
    }

    public trainChatbot = async (chatbotId: string, trainingSettings: TrainingSettings, options?: ClientOptions): Promise<Chatbot> => {
        return this.post(`/chatbot/${chatbotId}/train`, { body: trainingSettings, ...options });
    }

    public addUserKnowledgeToBot = async (chatbotId: string, options?: ClientOptions): Promise<String> => {
        return this.post(`/chatbot/${chatbotId}/addUserKnowledge`, { body: chatbotId, ...options });
    }

    public chatWithChatbot = async (chatbotId: string, chatRequest: ChatRequest, 
                                    onEvent: (event: string, event_type: string) => void, 
                                    onClose: (lastChunkReceived: string) => void, options?: ClientOptions): Promise<void> => {
        return this.postStream(`/chatbot/${chatbotId}/chat`, onEvent, onClose, { body: chatRequest, ...options });
    }

    public setChatbotTrainingStatus = async (chatbotId: string, trainingStatus: string, options?: ClientOptions): Promise<Chatbot> => {
        return this.post(`/chatbot/${chatbotId}/training_status`, { body: { trainingStatus }, ...options })
    }

    public setChatbotAvailability = async (chatbotId: string, publicAvailable: boolean, options?: ClientOptions): Promise<Chatbot> => {
        return this.post(`/chatbot/${chatbotId}/availability`, { body: { publicAvailable }, ...options })
    }

    public setChatbotSettings = async (chatbotId: string, settings: ChatbotSettings, options?: ClientOptions): Promise<Chatbot> => {
        return this.post(`/chatbot/${chatbotId}/settings`, { body: { settings }, ...options })
    }

    public giveFeedback = async (chatbotId: string, feedbackContent: FeedbackContent, options?: ClientOptions): Promise<Chatbot> => {
        return this.post(`/chatbot/${chatbotId}/feedback`, { body: { feedbackContent }, ...options })
    }

    public createFileForChatbot = async (chatbotId: string, file: Pick<FileEntity, "name" | "subfolder" | "size" | "md5Hash">, options?: ClientOptions): Promise<FileEntity> => {
        return this.post(`/chatbot/${chatbotId}/files`, { body: file, ...options });
    }

    public translate = async (translationRequest: TranslationRequest, options?: ClientOptions): Promise<Chatbot> => {
        return this.post(`/commonservice/translate`, { body: translationRequest, ...options });
    }

    public scrapePage = async (scrapeRequest: ScrapeRequest): Promise<string> => {
        return this.post(`/scraper`, { body: scrapeRequest });
    }

    public subsribeToScrapeStatus = async (scraperID: string,
                                    onEvent: (event: string, event_type: string) => void,
                                    onClose: (lastChunkReceived: string) => void, options?: ClientOptions): Promise<void> => {
        return this.postStream(`/scraper/task-progress/${scraperID}`, onEvent, onClose,  options);
    }
    
}


export const ChatbotApiClientContext = createContext<ChatbotApiClient>(new ChatbotApiClient())

export const useChatbotApiClient = (defaultConfig?: ClientOptions) => {
    const context = useContext(ChatbotApiClientContext)
    context.notificationCtx = useNotificationContext()
    if (defaultConfig) {
        context.defaultConfig = defaultConfig
    }
    return context
}
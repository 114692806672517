import React from 'react';
import {Grid, Typography, Button, Box, Fade} from '@mui/material';
import useHomeHeaderStyles from "../../../shared/styles";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const techImage = "/img/tech_overview.png"

const Tech = () => {
    const classes = useHomeHeaderStyles();
    const { t } = useTranslation();

    return (
        <Box sx={classes.heroBox}>
            <Grid container spacing={6} sx={classes.gridContainer}>
                
                <Grid item xs={12} md={7}>
                    <Typography variant="h3" fontWeight={700} sx={classes.title}>
                        {t('homepage.tech0')}
                    </Typography>
                    <Typography variant="h6" sx={classes.aboutUsSubtitle}>
                        {t('homepage.tech1')}
                    </Typography>
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{ width: '200px', fontSize: '16px' }}
                        component={NavLink} to="/login"
                    >
                        {t('homepage.tech2')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Fade in={true} timeout={1000}>
                        <Grid item sx={{color: '#E3E3E3', fontSize: '7px', textAlign: 'center'}}>
                            <Box src={techImage} component="img" sx={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '500px'}}/>
                            Tech Overview BotBucket
                        </Grid>
                    </Fade>

                </Grid>
                
            </Grid>
        </Box>
    );
};

export default Tech;
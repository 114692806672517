import React from 'react';
import { List, ListItem, ListItemIcon } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useTranslation } from "react-i18next";
import { Product } from '../../../model/Product';

interface ProductDescriptionListProps {
    product: Product;
}

const ProductDescriptionList: React.FC<ProductDescriptionListProps> = ({ product }) => {
    const { t } = useTranslation();

    return (
        <List>
            {Array.from({ length: 3 }, (_, index) => index + 1).map((number) => (
                <ListItem key={number}>
                    <ListItemIcon style={{ minWidth: "30px" }}>
                        <ArrowRightIcon fontSize="medium" />
                    </ListItemIcon>
                    {t(`overview.${product.name.toLowerCase()}.description${number}`)}
                </ListItem>
            ))}
        </List>
    );
}

export default ProductDescriptionList;

import Cookies from "universal-cookie";
import {UserContext} from "../shared/contexts/UserContext";
import { NotificationContext } from "../shared/contexts/NotificationContext";
import { ErrorResponse } from "./error/ErrorResponse";

export enum idProvider {
    BOTBUCKET = "BOTBUCKET",
    GOOGLE = "GOOGLE",
    FACEBOOK = "FACEBOOK",
}

export interface ClientOptions {
    preventTokenRefresh?: boolean
    preventBodyStringify?: boolean
    preventAuthorization?: boolean
    preventNotification?: boolean
}

export interface RequestOptions extends ClientOptions {
    body?: unknown
    preventTokenRefresh?: boolean
    preventBodyStringify?: boolean
    preventAuthorization?: boolean
    preventNotification?: boolean
}

declare global {
    interface Window { REACT_APP_BASE_URL: string }
}

export default class ApiClient {
    protected BASE_URL: string;
    protected AUTHENTICATION_HEADER: boolean;

    private _defaultConfig: ClientOptions;

    private _userCtx: UserContext | undefined;
    private _notificationCtx: NotificationContext | undefined;

    constructor() {
        this.BASE_URL = window.REACT_APP_BASE_URL;
        this.AUTHENTICATION_HEADER = true;
        this._defaultConfig = {}
    }

    private optionsWithDefault = (options: RequestOptions): RequestOptions => {
        return {
            body: options.body,
            preventAuthorization: options.preventAuthorization === undefined ? this.defaultConfig.preventAuthorization : options.preventAuthorization,
            preventNotification: options.preventNotification === undefined ? this.defaultConfig.preventNotification : options.preventNotification,
            preventBodyStringify: options.preventBodyStringify === undefined ? this.defaultConfig.preventBodyStringify : options.preventBodyStringify,
            preventTokenRefresh: options.preventTokenRefresh === undefined ? this.defaultConfig.preventTokenRefresh : options.preventTokenRefresh,
        }
    }

    protected request = async (method: string, url: string, options: RequestOptions) => {
        options = this.optionsWithDefault(options)
        const cookies = new Cookies()

        const headers: HeadersInit = new Headers()
        !options.preventAuthorization && headers.append('Authorization', ('Bearer ' + (cookies.get("token") ?? '')));
        headers.set('Content-Type', 'application/json')
        headers.set('X-ID-PROVIDER', cookies.get("x-id-provider"))

        const parseBody = (): string | undefined => {
            if (options.body) {
                if (options.preventBodyStringify) {
                    return options.body as string
                } else {
                    return JSON.stringify(options.body)
                }
            }
            return undefined
        }

        return fetch(this.BASE_URL + url, {
            method: method,
            headers: headers,
            body: parseBody()
        }).then((response: Response) => {
            if (response.status < 200 || response.status >= 300) {
                throw response;
            }
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json();
            } else {
                return response.text();
            }
        })
            .catch(async (error: Response | unknown) => {
                if (error instanceof Response) {
                    if (error.status === 401 && this.userCtx && !options.preventTokenRefresh) {
                        this.userCtx.refreshToken()
                    }
                    const body = await error.json() as ErrorResponse
                    body.status = error.status
                    if (this.notificationCtx && body.message && !options.preventNotification) {
                        this.notificationCtx.notify({ message: body.message, severity: 'error' })
                    }
                    throw body;
                }
                throw error
            });
    }
    protected get = async (url: string, options?: RequestOptions) => {
        return this.request('GET', url, options ?? {});
    }

    protected post = async (url: string, options?: RequestOptions) => {
        return this.request('POST', url, options ?? {});
    }

    protected put = async (url: string, options?: RequestOptions) => {
        return this.request('PUT', url, options ?? {});
    }

    protected patch = async (url: string, options?: RequestOptions) => {
        return this.request('PATCH', url, options ?? {});
    }

    protected delete = async (url: string, options?: RequestOptions) => {
        return this.request('DELETE', url, options ?? {});
    }
    
    protected postStream = async (url: string, 
                                  onEvent: (event: string, event_type: string, timestamp?: string) => void, 
                                  onClose: (lastChunkReceived: string) => void, 
                                  options?: RequestOptions, retries = 0) => {
        const cookies = new Cookies()
        try {
            let headers:{[key:string]: string} = {}
            
            if (cookies.get("token")) {
                headers['Authorization'] = ('Bearer ' + (cookies.get("token") ?? ''))
                headers['X-ID-PROVIDER'] = cookies.get("x-id-provider")
            }
            headers['Content-Type'] = 'application/json'
            headers['Accept'] = 'text/event-stream'
            
            const response = await fetch(this.BASE_URL + url, {
                method: 'POST',
                headers: headers,
                body: (options && options.body ? JSON.stringify(options.body) : undefined)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const reader = response.body?.getReader();
            let receivedText = '';
            let partialJSON = '';
            let lastFullMessage = '';
            

            const processText = ({ done, value }: ReadableStreamReadResult<Uint8Array>) => {
                if (done) {
                    console.log('Stream finished.');
                    onClose(lastFullMessage); // Call the close callback
                    return;
                }

                if (value) {
                    const chunk = new TextDecoder().decode(value);
                    receivedText += chunk;

                    // Process server-sent events
                    const events = receivedText.split(/\n\n/);
                    for (const eventString of events.slice(0, -1)) {
                        if (eventString.trim() !== '') {
                            const hadNewline = eventString.indexOf("\n") > 0
                            const eventData = eventString.split(/\n/)[0].replace(/^data:/, '');
                            if (eventData) {
                                let fullMsg = (partialJSON + eventData) + (hadNewline ? "\n" : "").replaceAll("\n", "<br/>")
                                if (fullMsg.trim().endsWith("}<br/>")) {
                                    fullMsg = fullMsg.replaceAll("}<br/>", "}")
                                }
                                if (fullMsg.trim().endsWith("}") || fullMsg.trim().endsWith("}<br/>")) {
                                    partialJSON = '';
                                    lastFullMessage = fullMsg;
                                    const resObj = JSON.parse(fullMsg)
                                    if ('answer_status' in resObj) {
                                        onEvent(resObj['answer_chunk'], 'answer_status', resObj['timestamp'])
                                    } else if ('answer_chunk' in resObj) {
                                        onEvent(resObj['answer_chunk'], 'answer_chunk', resObj['timestamp'])
                                    } else {
                                        onEvent(fullMsg, 'other')
                                    }
                                } else {
                                    partialJSON = partialJSON + eventData;
                                    // more parts to collect until we have a full json
                                }
                                // setMessages((prevMessages) => [...prevMessages, eventData]);
                            }
                        }
                    }

                    receivedText = events[events.length - 1]; // Keep the last partial event string
                }

                // Read the next chunk
                reader?.read().then(processText);
            };

            reader?.read().then(processText);

        } catch (error) {

            console.error("Error or stream closed unexpectedly:", error);

            // Attempt to reconnect after an error
            if (retries > 0) {
                console.log(`Reconnecting... Attempts left: ${retries}`);
                setTimeout(() => {
                    this.postStream(url, onEvent, onClose, options, retries - 1);
                }, 500); // Retry after 0.5 seconds
            } else {
                console.error("Maximum retry attempts reached. Could not reconnect.");
            }
            
            console.error('Failed to fetch:', error);
        }
    };



    public get notificationCtx(): NotificationContext | undefined {
        return this._notificationCtx;
    }
    public set notificationCtx(value: NotificationContext | undefined) {
        this._notificationCtx = value;
    }
    public get userCtx(): UserContext | undefined {
        return this._userCtx;
    }
    public set userCtx(value: UserContext | undefined) {
        this._userCtx = value;
    }
    public get defaultConfig(): ClientOptions {
        return this._defaultConfig;
    }
    public set defaultConfig(value: ClientOptions) {
        this._defaultConfig = value;
    }
}
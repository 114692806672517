import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    TextField,
    Slide,
    Typography,
    Grid,
    Container,
    Alert,
} from "@mui/material";
import Headline from "../../../shared/components/Headline";
import PasswordInput from "../../../shared/components/PasswordInput";
import { LoadingButton } from "@mui/lab";
import { useAuthApiClient } from "../../../clients/AuthApiClient";
import { useUserContext } from "../../../shared/contexts/UserContext";
import { useUserApiClient } from "../../../clients/UserApiClient";
import { ErrorResponse } from "../../../clients/error/ErrorResponse";
import useHomeHeaderStyles from "../../../shared/styles";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Transition = React.forwardRef(function Transition(
    props: any,
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref as any} {...props} />;
});

interface ReusableDialogProps {
    open: boolean;
    onClose: () => void;
  }

function LoginDialog(props: ReusableDialogProps) {

    const { open, onClose } = props;


    const authApiClient = useAuthApiClient({ preventNotification: true });
    const userClient = useUserApiClient({ preventNotification: true });
    const userCtx = useUserContext();
    
    const [loginError, setLoginError] = useState<string>();

    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const classes = useHomeHeaderStyles();

    const { t } = useTranslation();

    const handleLogin = () => {
        setLoading(true);
        authApiClient
            .signin(username, password)
            .then(userCtx.login)
            .then(() => userClient.getCurrentUser())
            .then(userCtx.setCurrentUser)
            .catch((err: ErrorResponse) => setLoginError(err.message))
            .finally(() => setLoading(false));

        onClose();
    };

    const redirectUrl = window.location.href.replace(
        window.location.origin,
        ""
    );

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
            >
                <DialogContent>
                    <Grid
                        container
                        justifyContent="center"
                        alignContent="center"
                        direction="row"
                        sx={{
                            position: "relative",
                            backgroundColor: "white",
                        }}
                    >
                        <Container>
                            <Grid
                                container
                                spacing={3}
                                sx={{
                                    marginTop: "5vh",
                                    marginBottom: "5vh",
                                    paddingLeft: "15%",
                                    paddingRight: "15%",
                                }}
                                onKeyDownCapture={(e) => {
                                    if (e.key === "Enter") {
                                        handleLogin();
                                    }
                                }}
                            >
                                <Grid item xs={12}>
                                    <Headline
                                        showLogo
                                        title={t("login.login")}
                                    />
                                </Grid>
                                {loginError && (
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        justifyContent="center"
                                    >
                                        <Alert severity="error">
                                            {loginError}
                                        </Alert>
                                    </Grid>
                                )}

                                <Grid
                                    item
                                    xs={12}
                                    container
                                    justifyContent="center"
                                >
                                    <TextField
                                        label={t("login.usernameOrEmail")}
                                        variant="outlined"
                                        fullWidth
                                        sx={classes.inputField}
                                        value={username}
                                        onChange={(e) =>
                                            setUsername(e.target.value)
                                        }
                                    />

                                    <PasswordInput
                                        sx={{ width: "100%" }}
                                        password={password}
                                        onPasswordChange={setPassword}
                                        title={t("login.password")}
                                        onSubmit={handleLogin}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    columnSpacing={1}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Typography variant="body2">
                                            {t("login.forgotPassword")}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            component={NavLink}
                                            to="/reset-password"
                                        >
                                            {t("login.resetPassword")}
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    spacing={2}
                                    container
                                    justifyContent="center"
                                >
                                    <Grid item>
                                        <Button
                                            sx={{
                                                width: "200px",
                                                fontSize: "16px",
                                            }}
                                            variant="outlined"
                                            component={NavLink}
                                            to={"/register?redirect=" +
                                            redirectUrl}
                                        >
                                            {t("login.navToSignUp")}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <LoadingButton
                                            loading={loading}
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                                width: "200px",
                                                fontSize: "16px",
                                            }}
                                            onClick={handleLogin}
                                        >
                                            {t("login.login")}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default LoginDialog;

import * as React from 'react';
import { Chatbot, TrainingStatus } from '../../../model/Chatbot';
import { TableRow, TableCell, Grid, ListItem, ListItemAvatar, Avatar, ListItemText, Button } from '@mui/material';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import TrainingStatusIcon from './TrainingStatusIcon';
import { useState } from 'react';
import { useChatbotApiClient } from '../../../clients/ChatbotApiClient';
import { LoadingButton } from '@mui/lab';
import ConfirmDialog from '../../../shared/components/dialogs/ConfirmDialog';

export interface IChatbotTableRowProps {
    chatbot: Chatbot
    onDelete?(): void
}

const ChatbotTableRow: React.FunctionComponent<IChatbotTableRowProps> = (props: IChatbotTableRowProps) => {
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const chatbotClient = useChatbotApiClient()

    const deleteChatbot = () => {
        setDeleting(true)
        chatbotClient.deleteChatbot(props.chatbot.id)
            .then(() => props.onDelete && props.onDelete())
            .catch(() => { })
            .finally(() => setDeleting(false))
    }

    return (
        <TableRow key={props.chatbot.id}>
            <TableCell width="35%">
                <Grid container>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar alt={props.chatbot.name + " " + props.chatbot.description} src={
                                (props.chatbot.name === "Benni-Bot" ? "https://media.licdn.com/dms/image/C4D03AQG82mo90POjqA/profile-displayphoto-shrink_400_400/0/1575535448177?e=1712793600&v=beta&t=rNhgC-ZW6CSDII5OTKJfdZhHUw0dTbkXe1pHabIA64A" :
                                (props.chatbot.name === "Albert" ? "/img/ulm/Albert_hg.png" :
                                (props.chatbot.name.startsWith("Spätzle") ? "/img/ulm/Spaetzle_hg.png" :
                                (props.chatbot.name.startsWith("sTHUdy") ? "/img/thu/sTHUdyBuddy_small.png" :
                                (props.chatbot.name.startsWith("Lumi") ? "/img/ulm/u-Lumi_small.png" :
                                (props.chatbot.name.startsWith("Theresia") ? "/img/ulm/Clara3.png" :
                                (props.chatbot.name.startsWith("Gabriel") ? "/img/ulm/Gabriel_logo2.png" :
                                        "/img/bb_solo_small.png"
                                )))))))
                            } />
                        </ListItemAvatar>
                        <ListItemText
                            primary={props.chatbot.name}
                            secondary={
                                <React.Fragment>
                                    {props.chatbot.description}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                </Grid>
            </TableCell>
            <TableCell width="15%" align="right"> {props.chatbot.files?.length ?? 0}</TableCell>
            <TableCell width="15%" align="right"> {props.chatbot.queryCounter ?? 0}</TableCell>
            <TableCell width="10%" align="center"> <TrainingStatusIcon status={props.chatbot.trainingStatus} /></TableCell>
            <TableCell width="25%">
                <Grid container spacing={1}>
                    <Grid item>
                        <Button disabled={props.chatbot.trainingStatus !== TrainingStatus.TRAINED} component={Link} to={`/bot/${props.chatbot.id}/chat  `} variant="contained">
                            {t('chatbots.chat')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button component={Link} to={`/bot/${props.chatbot.id}`} variant="outlined">
                            {t('chatbots.edit')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <LoadingButton loading={deleting} variant="outlined" color="error" onClick={() => setConfirmDeleteOpen(true)} >
                            {t('chatbots.delete')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </TableCell>
            <ConfirmDialog
                open={confirmDeleteOpen}
                onClose={() => setConfirmDeleteOpen(false)}
                confirmQuestion={t('chatbot.confirmDelete', { botName: props.chatbot.name })}
                confirmPhrase={props.chatbot.name}
                onConfirm={() => deleteChatbot()}
                severity={'error'} />
        </TableRow>
    );
}

export default ChatbotTableRow;
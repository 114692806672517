import { createContext, useContext } from "react";
import ApiClient, { ClientOptions } from "./ApiClient";
import { useNotificationContext } from "../shared/contexts/NotificationContext";
import { Product } from "../model/Product";
import { Price } from "../model/Price";
import { Subscription } from "../model/Subscription";

export default class ApiStripeClient extends ApiClient {

    public getProducts = async () : Promise <{[key: string]: Product}> => {
        return this.get('/stripe/products', {preventAuthorization: true})
    }

    public getPrices = async () : Promise <{[key: string]: Price}> => {
        return this.get('/stripe/prices', {preventAuthorization: true})
    }

    public getProductsSorted = async () : Promise <{[key: string]: Product}> => {
        return this.get('/stripe/products-sorted', {preventAuthorization: true})
    }

    public getActiveSubscriptions = async (stripeCustomerId: string) : Promise <Subscription[]> => {
        return this.get(`/stripe/subscriptions/active/${stripeCustomerId}`)
    }

    public createCheckoutSession = async (email: string, priceId: string, customerId: string) : Promise<string> => {
        return this.post('/stripe/create-checkout-session', {body: {email, priceId, customerId}})
    }

    public customerPortalUrl = async (customerId: string, email: string, userName: string) : Promise<string> => {
        return this.post('/stripe/customer-portal', {body: {customerId, email, userName}})
    }

    public getLatestInvoice = async (customerId: string) : Promise<string> => {
        return this.get(`/stripe/invoices/latest/${customerId}`)
    }
 
}

export const ApiStripeClientContext = createContext<ApiStripeClient>(new ApiStripeClient())

export const useApiStripeClient = (defaultConfig?: ClientOptions) => {
    const context = useContext(ApiStripeClientContext)
    context.notificationCtx = useNotificationContext()
    if (defaultConfig) {
        context.defaultConfig = defaultConfig
    }
    return context
}


import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import SchoolIcon from '@mui/icons-material/School';
import LanguageIcon from '@mui/icons-material/Language';
import useHomeHeaderStyles from "../../../shared/styles";
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";

const Section = () => {
    const classes = useHomeHeaderStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const sectionItems = [
        {
            id: 1,
            icon: <EngineeringOutlinedIcon sx={{ fontSize: 100, color: theme.palette.primary.main }} />,
            sentence: t('homepage.section0'),
        },
        {
            id: 2,
            icon: <SelfImprovementIcon sx={{ fontSize: 100, color: theme.palette.primary.main }} />,
            sentence: t('homepage.section1'),
        },
        {
            id: 3,
            icon: <SchoolIcon sx={{ fontSize: 100, color: theme.palette.primary.main }} />,
            sentence: t('homepage.section2'),
        },
        {
            id: 4,
            icon: <LanguageIcon sx={{ fontSize: 100, color: theme.palette.primary.main }} />,
            sentence: t('homepage.section3'),
        },
        {
            id: 5,
            icon: <PaidOutlinedIcon sx={{ fontSize: 100, color: theme.palette.primary.main }} />,
            sentence: t('homepage.section4'),
        },
    ];
    return (
        <Box sx={{ flexGrow: 1, minHeight: '400px' }}>
            <Grid container sx={classes.sectionGridContainer}>
                {sectionItems.map((item) => (
                    <Grid
                        item
                        xs={12}
                        md={3.5}
                        minHeight={300}
                        key={item.id}
                        sx={classes.sectionGridItem}
                    >
                        {item.icon}
                        <Typography>{item.sentence}</Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Section;
import * as React from 'react';
import { Typography, useTheme } from '@mui/material';

export interface IPage404Props {

}

const Page404: React.FunctionComponent<IPage404Props> = (props: IPage404Props) => {
    const theme = useTheme()

    return (
        <>
            <Typography variant="h3" sx={{marginBottom: theme.spacing(1)}}>Page not found</Typography>
        </>
    );
}

export default Page404;
import i18n from "../i18n/I18n"

export interface ValidationResponse {
    valid: boolean
    error?: string
}

export const validateChatbotname = (chatbotName?: string): ValidationResponse => {
    if (!chatbotName) {
        return { valid: false }
    }
    if (chatbotName.length < 6) {
        return { valid: false, error: i18n.t('validations.chatbotNameMinChar') }
    }
    return { valid: true }
}

export const validateUsername = (username?: string): ValidationResponse => {
    if (!username) {
        return { valid: false }
    }
    if (username.length < 3) {
        return { valid: false, error: i18n.t('validations.usernameMinChar') }
    }
    return { valid: true }
}

export const validateEmail = (email?: string): ValidationResponse => {
    if (!email) {
        return { valid: false }
    }
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return { valid: false, error: i18n.t('validations.enterValidMail') }
    }
    return { valid: true }
}

export const validatePassword = (password?: string): ValidationResponse => {
    if (!password) {
        return { valid: false }
    }
    if (password.length < 8) {
        return { valid: false, error: i18n.t('validations.passwordMinChar') }
    }
    if (!/\d/.test(password)) {
        return { valid: false, error: i18n.t('validations.passwordContainDigit') }
    }
    if (!/[A-Z]/.test(password)) {
        return { valid: false, error: i18n.t('validations.passwordContainUppercase')}
    }
    if (!/[a-z]/.test(password)) {
        return { valid: false, error: i18n.t('validations.passwordContainLowercase') }
    }
    return { valid: true }
}


export const validateRepeatPassword = (password?: string, repeatPassword?: string): ValidationResponse => {
    if (!repeatPassword || !password) {
        return { valid: false }
    }
    if (password !== repeatPassword) {
        return { valid: false, error: i18n.t('validations.passwordSame') }
    }
    return { valid: true }
}


import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Headline from "../../shared/components/Headline";
import { useUserContext } from "../../shared/contexts/UserContext";
import { useTranslation } from "react-i18next";
import { useApiStripeClient } from "../../clients/ApiStripeClient";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

export interface IProfileProps {}

const Profile: React.FunctionComponent<IProfileProps> = (
    props: IProfileProps
) => {
    const userContext = useUserContext();

    const stripeClient = useApiStripeClient();

    const { t } = useTranslation();

    const navigate = useNavigate();


    const [loading, setLoading] = useState(false);
    useEffect(() => {
        console.log(userContext.user)
    })

    // this case should never happen, since this page is protected and only logged in users can reach it :-)
    if (!userContext.user) {
        return <></>;
    }

    const pricingPage = () => {
        navigate("/pricing");
    }
 

    const handleProfile = () => {
        if (userContext.user?.stripeCustomerId) {
            setLoading(true);
            stripeClient
                .customerPortalUrl(userContext.user.stripeCustomerId, "", "")
                .then((url) => window.open(url, "_blank"))
                .catch(() => {})
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(true);
            stripeClient
                .customerPortalUrl("", userContext.user!.email, userContext.user!.username)
                .then((url) => window.open(url, "_blank"))
                .catch(() => {})
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <Headline showLogo title="Mein Profil" />
                </Grid>
                {loading ? (
                    <CircularProgress />
                ) : userContext.user.stripeCustomerId ? (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>

                    <Button
                        onClick={handleProfile}
                        variant="contained"
                        size="large"
                    >
                        {t("profile.updateProfileButton")}
                    </Button>
                    <Button
                        onClick={pricingPage}
                        variant="contained"
                        size="large"
                    >
                        {t("profile.pricesButton")}
                    </Button>
                    </div>
                    </>
                ) : (
                    <Grid
                        container
                        direction="column"
                        spacing={2}
                        alignItems="center"
                    >
                        <Grid item>
                            <h3>{t("profile.noCustomer")}</h3>{" "}
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={handleProfile}
                                variant="contained"
                                size="large"
                            >
                                {t("profile.createCustomer")}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default Profile;

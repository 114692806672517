import React from 'react';
import { Button, CircularProgress } from "@mui/material";
import { Product } from "../../../model/Product";
import { Subscription } from "../../../model/Subscription";
import { useTranslation } from "react-i18next";
import { useCustomThemeProps } from '../useCustomThemeProps';

interface ProductSubscriptionButtonProps {
    isLoading: boolean;
    product: Product;
    subscriptions: Subscription[];
    subscribe: (product: Product) => void;
}

const ProductSubscriptionButton: React.FC<ProductSubscriptionButtonProps> = ({
    isLoading,
    product,
    subscriptions,
    subscribe,
}) => {
    const { t } = useTranslation();

    const customTheme = useCustomThemeProps();

    if (isLoading) {
        return (
            <div style={customTheme.div}>
                <CircularProgress />
            </div>
        );
    } else if (subscriptions[0]?.productName === product.name) {
        return (
            <div style={customTheme.div}>
                <Button
                    style={customTheme.activeSubscribeButtons}
                    disabled
                >
                    {t("prices.active")}
                </Button>
            </div>
        );
    } else {
        return (
            <div style={customTheme.div}>
                {product.name === "Free" ? (
                    <Button
                        onClick={() => subscribe(product)}
                        style={customTheme.subscribeButtons}
                    >
                        {t("prices.join")}
                    </Button>
                ) : (
                    <Button
                        onClick={() => subscribe(product)}
                        style={customTheme.subscribeButtons}
                    >
                        {t("prices.subscribe")}
                    </Button>
                )}
            </div>
        );
    }
};

export default ProductSubscriptionButton;

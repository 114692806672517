import React from 'react';
import {Grid, Typography, Button, Box, Fade} from '@mui/material';
import useHomeHeaderStyles from "../../../shared/styles";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const landingImage = "/img/bb.png"

const Hero = () => {
    const classes = useHomeHeaderStyles();
    const { t } = useTranslation();

    return (
        <Box sx={classes.heroBox}>
            <Grid container spacing={6} sx={classes.gridContainer}>
                <Grid item xs={12} md={5}>
                    <Fade in={true} timeout={1000}>
                        <Grid item sx={{color: '#E3E3E3', fontSize: '7px', textAlign: 'center'}}>
                            <Box src={landingImage} component="img" sx={{ display: 'block', marginLeft: 'auto', marginRight: 'auto'}}/>
                            A cute robot whose head is a bucket with big eyes who is happy because books are falling from the sky in digital art
                        </Grid>
                    </Fade>
                    
                </Grid>
                <Grid item xs={12} md={7}>
                    <Typography variant="h3" fontWeight={700} sx={classes.title}>
                        BotBucket
                    </Typography>
                    <Typography variant="h6" sx={classes.aboutUsSubtitle}>
                        {t('homepage.hero0')}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ width: '200px', fontSize: '16px', color: 'white' }}
                        component={NavLink} to="/register"
                    >
                        {t('homepage.hero1')}
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{ width: '200px', fontSize: '16px' }}
                        component={NavLink} to="/login"
                    >
                        Login
                    </Button>
                </Grid>
                
            </Grid>
        </Box>
    );
};

export default Hero;
import { Box, Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {Navigate, useNavigate, useParams, useSearchParams} from "react-router-dom";
import Chat from "./chatbot/Chat";
import ChatBotAppStyles from "./ChatBotAppStyles";
import { Chatbot } from "../../model/Chatbot";
import GlobalLoader from "../../shared/components/GlobalLoader";
import { useChatbotApiClient } from "../../clients/ChatbotApiClient";


export interface MyChatBotChatProps {
    botId?: string;  // botId is optional in the component props
}

const MyChatBotChat: React.FunctionComponent<MyChatBotChatProps> = (props: MyChatBotChatProps) => {
    // const { t } = useTranslation();
    // const { botId } = useParams()
    const { botId: paramBotId } = useParams<{ botId?: string }>();  // botId from URL params can be optional
    // const location = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const chatbotClient = useChatbotApiClient()

    // Use either the paramBotId or propBotId, with fallback logic
    const botId = paramBotId || props.botId;

    const [questionToAsk, setQuestionToAsk] = React.useState<string>("")
    const [botTopics, setBotTopics] = useState<string[]>(["Ultris-5", "Ultris-X20"])
    const [bot, setBot] = useState<Chatbot>()
    const fetchChatbot = useCallback(() => {
        if (botId) {
            chatbotClient.getChatbot(botId)
                .then((theBot: Chatbot) => {
                    setBot(theBot)
                    // fetch all topics:
                    const topics: string[] = []
                    theBot.files?.forEach((fileEntity) => {
                        if (fileEntity.topic) {
                            fileEntity.topic.split(",").forEach((entry: string) => {
                                entry = entry.trim()
                                if (!topics.includes(entry)) {
                                    topics.push(entry)
                                }
                            })
                        }
                    })
                    setBotTopics(topics)
                })
                .catch((err) => { 
                    console.error(err)
                    navigate('/chatBots') 
                })
        }
    }, [botId, chatbotClient, navigate])

    useEffect(() => {
        fetchChatbot()
    }, [fetchChatbot])

    // if called via iFrame: listen for incomming postMessage events from parent window to the iFrame
    const questionHandler = ((e: MessageEvent) => {
        if (e.data.type !== 'questionToAsk') return
        console.log("questionToAsk: " + e.data.message);
        setQuestionToAsk(e.data.message)
    }) as EventListener

    React.useEffect(() => {
        window.addEventListener("message", questionHandler);
        return () => window.removeEventListener('message', questionHandler)
        // eslint-disable-next-line
    }, []);

    
    const pathname = window.location.pathname

    // Reading specific query parameters
    const nlpTask = searchParams.get('nlpTask');
    const nlpModel = searchParams.get('nlpModel');


    if (!botId) {
        console.log("botId was empty")
        return <Navigate to={'/chatBots'} />
    }

    if (!bot) {
        return <GlobalLoader />
    }
   
    return (
            <Grid container justifyContent="center" alignContent="center" direction="row" sx={{
                position: "relative",
                zIndex: 10,
                justifyContent: "space-evenly",
                alignItems: "center",
            }}>
                {/*!pathname.endsWith("/undecorated") && <Grid item xs={12}>
                    <Headline title={t('chat.myChatWith', { botName: bot.name })} />
                </Grid>*/}
                <Grid item xs={12} justifyContent='center'>
                    <Box sx={ChatBotAppStyles}>
                        <Chat 
                            botId={bot.id} 
                            botName={bot.name} 
                            botTopics={botTopics} 
                            botDescription={bot.description}
                            botSettings={bot.settings}
                            nlpTask={nlpTask || "RAG"}
                            nlpModel={nlpModel}
                            undecorated={(pathname.endsWith("/undecorated"))}
                            questionToAsk={(questionToAsk && questionToAsk.length > 0 ? questionToAsk : undefined )}
                        />
                    </Box>
                </Grid>
            </Grid>
        );
};

export default MyChatBotChat;
import ApiClient, { ClientOptions } from "./ApiClient";
import { createContext, useContext } from "react";
import { User } from "../model/User";
import { UserPagination } from "../model/UserPagination";
import { useNotificationContext } from "../shared/contexts/NotificationContext";

export default class UserApiClient extends ApiClient {
    public getAllUsers = async (page: number, pageSize?: number, options?: ClientOptions): Promise<UserPagination> => {
        return this.get(`/user/all?page=${page}&pageSize=${pageSize ?? 20}`, options);
    }

    public getUser = async (userId: number, options?: ClientOptions): Promise<User> => {
        return this.get(`/user/${userId}`, options);
    }

    public getCurrentUser = async (options?: ClientOptions): Promise<User> => {
        return this.get('/user/me', options);
    }

    public deleteCurrentUser = async (options?: ClientOptions) => {
        return this.delete('/user/me', options);
    }
}


export const UserApiClientContext = createContext<UserApiClient>(new UserApiClient())

export const useUserApiClient = (defaultConfig?: ClientOptions) => {
    const context = useContext(UserApiClientContext)
    context.notificationCtx = useNotificationContext()
    if (defaultConfig) {
        context.defaultConfig = defaultConfig
    }
    return context
}
import { Modal, Grid, CircularProgress } from '@mui/material';
import * as React from 'react';

export interface IGlobalLoaderProps {
}

const GlobalLoader: React.FunctionComponent<IGlobalLoaderProps> = (props: IGlobalLoaderProps) => {
    return (
        <>
            <Modal open={true}>
                <Grid container sx={{ position: "fixed", top: 0, left: 0, height: "100%", width: "100%" }} justifyContent="center" alignContent="center">
                    <Grid item>
                        <CircularProgress color="error" size="10em" />
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
}

export default GlobalLoader;

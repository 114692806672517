import { createTheme } from "@mui/material";
import { CSSProperties } from "react";

export const useCustomThemeProps = () => {
    const theme = createTheme();

    const toggleButton = {
        padding: "0px 20px",
    };
    const toggleButtonGroup = {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(2),
    };

    const dividerMarginBottom = {
        marginBottom: theme.spacing(2),
    }

    const dividerMarginTop = {
        marginTop: theme.spacing(2),
    }

    const div: CSSProperties = {
        textAlign: "center",
    };

    const subscribeButtons = {
        backgroundColor: "#557A46",
        color: "white",
    };

    const activeSubscribeButtons = {
        backgroundColor: "#7A9D54",
        color: "white",
    }

    const grid = {
        marginTop: theme.spacing(6),
    }

    const link = {
        color: '#557A46',
    }

    return {
        toggleButton,
        toggleButtonGroup,
        div,
        subscribeButtons,
        activeSubscribeButtons,
        dividerMarginBottom,
        dividerMarginTop,
        grid,
        link
    };
};
import { Grid, Stack, TextField } from '@mui/material';
import * as React from 'react';
import Headline from '../../shared/components/Headline';
import { useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useChatbotApiClient } from '../../clients/ChatbotApiClient';
import { useNavigate } from 'react-router-dom';
import { validateChatbotname } from '../../shared/tools/validationTools';
import { useTranslation } from 'react-i18next';

export interface ICreateChatbotProps {
}

const CreateChatbot: React.FunctionComponent<ICreateChatbotProps> = (props: ICreateChatbotProps) => {

    const [name, setName] = useState("")
    const [nameError, setNameError] = useState<string>()
    const [description, setDescription] = useState("")

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const { t } = useTranslation()

    const chatbotClient = useChatbotApiClient()

    const nameValid = useMemo(() => {
        const validation = validateChatbotname(name)
        setNameError(validation.error)
        return validation.valid
    }, [name])


    const handleCreate = () => {
        if (nameValid) {
            setLoading(true)
            chatbotClient.createChatbot(name, description)
                .then(bot => navigate(`/bot/${bot.id}`))
                .catch(() => { })
                .finally(() => setLoading(false))
        }
    }

    return (
        <>
            <Grid container spacing={2} justifyContent='center'>
                <Grid item xs={12}>
                    <Headline showLogo title={t('createChatbots.createNewBotHeadline')} />
                </Grid>

                <Grid item xs={8}>
                    <Stack sx={{ width: "100%" }} spacing={2}>
                        <TextField
                            label={t('createChatbots.name')}
                            variant="outlined"
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            error={!!nameError}
                            helperText={nameError ?? ""}
                        />
                        <TextField
                            label={t('createChatbots.description')}
                            variant="outlined"
                            fullWidth
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Stack>
                </Grid>


                <Grid item xs={12} spacing={2} container justifyContent='center'>
                    <Grid item>
                        <LoadingButton
                            disabled={!nameValid}
                            loading={loading}
                            variant="contained"
                            color="primary"
                            sx={{ width: '200px', fontSize: '16px' }}
                            onClick={handleCreate}
                        >
                            {t('createChatbots.createBot')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default CreateChatbot;
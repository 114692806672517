import { AppBar, Typography, Link, Box, Toolbar, List, ListItemButton, ListItemText, IconButton, Drawer, Button, } from '@mui/material';
import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useHomeHeaderStyles from "../../../shared/styles";
import { NavLink } from "react-router-dom";
import LoginIcon from '@mui/icons-material/Login';
import LanguageSwitcher from "../../../shared/components/LanguageSwitcher";
import {useTranslation} from "react-i18next";


export const bbSoloImg = "/img/bb_solo_small.png"

export interface HomeHeaderProps { }

// https://blog.appseed.us/mui-react-coding-landing-page/
// https://github.com/app-generator/sample-react-mui-introduction/blob/main/src/styles/styles.js
// https://react-mui-landing-page.appseed-srv1.com/?ref=blog.appseed.us
const HomeHeader: React.FunctionComponent<React.PropsWithChildren<HomeHeaderProps>> = (props: HomeHeaderProps) => {
    const { t } = useTranslation();
    
    const homeHeaderStyles = useHomeHeaderStyles();
    const links = [
        { id: 1, route: t("homepage.header.products"), url: '/products' },
        // { id: 2, route: t("homepage.header.pricing"), url: '/pricing' },
        { id: 4, route: t("homepage.header.aboutUs"), url: '/aboutus' },
        { id: 4, route: t("homepage.header.docs"), url: '/docs' },
        { id: 5, route: t("homepage.header.contact"), url: '/contact' },
    ];

    const [state, setState] = React.useState({
        right: false,
    });

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };


    const toggleDrawer = (anchor: string, open: boolean) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (event.type === 'keydown') {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor: string) => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => toggleDrawer(anchor, false)}
        >
            <List>
                {links.map((link) => (
                    <ListItemButton key={link.id}>
                        <ListItemText primary={link.route} />
                    </ListItemButton>
                ))}
            </List>
        </Box>
    );

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{ marginBottom: '70px' }}>
            <AppBar>
                <Toolbar sx={homeHeaderStyles.toolBar}>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
                        <Box src={bbSoloImg} component="img" sx={{ height: '100%' }} />
                        <Link href="#" underline="none" sx={{ marginLeft: '30px', marginTop: 'auto', marginBottom: 'auto' }}>
                            <Typography variant="h5" sx={homeHeaderStyles.logo}>
                                BotBucket
                            </Typography>
                        </Link>
                    </Box>

                    {matches ? (
                        <Box>
                            <IconButton
                                size="large"
                                edge="end"
                                color="inherit"
                                aria-label="menu"
                                onClick={toggleDrawer('right', true)}
                            >
                                <MenuIcon sx={homeHeaderStyles.menuIcon} />
                            </IconButton>

                            <Drawer
                                anchor="right"
                                open={state['right']}
                                onClose={toggleDrawer('right', false)}
                            >
                                {list('right')}
                            </Drawer>
                        </Box>
                    ) : <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexGrow: '0.1',
                        }}
                    >
                        {links.map((link) => (
                            <Button key={link.id} sx={{ "&.active": { color: theme.palette.secondary.main, }, }}
                                component={NavLink} to={link.url} onClick={() => scrollToTop()}>
                                <Typography textAlign="center">{link.route}</Typography>
                            </Button>
                        ))}
                        
                        <LanguageSwitcher />
                        
                        <Button variant="contained" startIcon={<LoginIcon />} sx={{
                            color: "white",
                            "&.active": {
                                color: theme.palette.secondary.main,
                            },
                        }} component={NavLink} to="/login" onClick={() => scrollToTop()}>
                            <Typography textAlign="center">Login</Typography>
                        </Button>
                    </Box>}

                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default HomeHeader;
import { AppBar, Typography, Link, Box, Toolbar, List, ListItemButton, ListItemText, IconButton, Drawer, Button, Avatar, } from '@mui/material';
import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useHomeHeaderStyles from "../styles";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import HomeHeader from '../../pages/home/homepage/HomeHeader';
import { useUserContext } from '../contexts/UserContext';
import LoginIcon from '@mui/icons-material/Login';
import LanguageSwitcher from "./LanguageSwitcher";
import {useTranslation} from "react-i18next";

export interface HeaderBarProps { }


export const bbSoloImg = "/img/bb_solo_small.png"

const HeaderBar: React.FunctionComponent<React.PropsWithChildren<HeaderBarProps>> = (props: HeaderBarProps) => {

    const { t } = useTranslation();
    const location = useLocation()
    const navigate = useNavigate()
    const userCtx = useUserContext()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose()
        userCtx.logout(true)
    }
    const handleProfile = () => {
        handleClose()
        navigate('/profile')
    }

    const homeHeaderStyles = useHomeHeaderStyles();
    const links = [
        { id: 1, route: 'Dashboard', url: '/dashboard' },
        { id: 1, route: 'Docs', url: '/docs' },
    ];

    const [state, setState] = React.useState({
        right: false,
    });

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };


    const toggleDrawer = (anchor: string, open: boolean) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (event.type === 'keydown') {
            return;
        }

        setState({ ...state, [anchor]: open });
    };


    
    const list = (anchor: string) => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => toggleDrawer(anchor, false)}
        >
            <List>
                {links.map((link) => (
                    <ListItemButton key={link.id}>
                        <ListItemText primary={link.route} />
                    </ListItemButton>
                ))}
            </List>
        </Box>
    );


    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    if (location.pathname === "/") {
        return <HomeHeader />
    }

    return (
        <Box sx={{ marginBottom: '70px' }}>
            <AppBar>
                <Toolbar sx={homeHeaderStyles.toolBar}>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
                        <Box src={bbSoloImg} component="img" sx={{ height: '100%' }} />
                        <Link href="/" underline="none" sx={{ marginLeft: '30px', marginTop: 'auto', marginBottom: 'auto' }}>
                            <Typography variant="h5" sx={homeHeaderStyles.logo}>
                                BotBucket
                            </Typography>
                        </Link>
                    </Box>

                    {matches ? (
                        <Box>
                            <IconButton
                                size="large"
                                edge="end"
                                color="inherit"
                                aria-label="menu"
                                onClick={toggleDrawer('right', true)}
                            >
                                <MenuIcon sx={homeHeaderStyles.menuIcon} />
                            </IconButton>

                            <Drawer
                                anchor="right"
                                open={state['right']}
                                onClose={toggleDrawer('right', false)}
                            >
                                {list('right')}
                            </Drawer>
                        </Box>
                    ) : <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexGrow: '0.1',
                        }}
                    >
                    {userCtx.isLoggedIn() && links.map((link) => (
                        <Button key={link.id} sx={{ "&.active": { color: theme.palette.primary.main, }, }}
                            component={NavLink} to={link.url} onClick={() => scrollToTop()}>
                            <Typography textAlign="center">{link.route}</Typography>
                        </Button>
                    ))}

                    <LanguageSwitcher />
                        
                        {userCtx.isLoggedIn() ? (
                            <div>
                                {/*<IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="primary"
                                >
                                    <AccountCircle />
                                </IconButton>*/}
                                

                                
                                    <Avatar src={userCtx.getProfilePicture()} sx={{ bgcolor: theme.palette.primary.main, cursor: 'pointer' }}
                                            onClick={handleMenu}>
                                    </Avatar>
                                
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleProfile}>{t("userMenu.profile")}</MenuItem>
                                    <MenuItem onClick={handleClose}>{t("userMenu.settings")}</MenuItem>
                                    <MenuItem onClick={handleLogout}>{t("userMenu.logout")}</MenuItem>
                                </Menu>
                            </div>
                        ) :
                            <Button variant="contained" startIcon={<LoginIcon />} sx={{
                                color: "white",
                                "&.active": {
                                    color: theme.palette.secondary.main,
                                },
                            }} component={NavLink} to="/login" onClick={() => scrollToTop()}>
                                <Typography textAlign="center">{t("userMenu.login")}</Typography>
                            </Button>}
                    </Box>}

                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default HeaderBar;
export enum ErrorType {
    ENTITY_NOT_FOUND = "ENTITY_NOT_FOUND",
    INVALID_INPUT = "INVALID_INPUT",
    UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
    BAD_REQUEST = "BAD_REQUEST",
    ACCESS_DENIED = "ACCESS_DENIED",
    MAIL_ERROR = "MAIL_ERROR",
}

export interface ErrorResponse {
    type: ErrorType
    message: string
    status?: number
}

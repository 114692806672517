import React, { useEffect, useState } from "react";
import { useApiStripeClient } from "../../clients/ApiStripeClient";
import { useUserContext } from "../../shared/contexts/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useCustomThemeProps } from "./useCustomThemeProps";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

function Success() {
    const stripeClient = useApiStripeClient();

    const userContext = useUserContext();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const customTheme = useCustomThemeProps();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const status = params.get("status") || undefined;

    const [url, setUrl] = useState("");

    useEffect(() => {
        if (status === "success")
        stripeClient
            .getLatestInvoice(userContext.user!.stripeCustomerId)
            .then((url) => setUrl(url))
            .catch(() => {});
    }, [stripeClient, userContext, status]);

    const returnDashboard = () => {
        navigate("/dashboard");
    };

    function UpdatedComponent() {
        return (
            <>
                <h1>{t("success.headline.updated")}</h1>
                <Button
                    onClick={returnDashboard}
                    variant="contained"
                    style={customTheme.dividerMarginTop}
                >
                    {t("success.button")}
                </Button>
            </>
        );
    }

    function SuccessComponent() {
        return (
            <>
                <h1>{t("success.headline.success")}</h1>
                <div>
                    {t("success.invoice")}
                    <a
                        style={{
                            ...customTheme.link,
                            ...customTheme.dividerMarginTop,
                        }}
                        href={url}
                    >
                        {t("success.invoiceLink")}
                    </a>
                </div>
                <Button
                    onClick={returnDashboard}
                    variant="contained"
                    style={customTheme.dividerMarginTop}
                >
                    {t("success.button")}
                </Button>
            </>
        );
    }

    function CancelComponent() {
        return (
            <>
                <h1>{t("success.headline.cancel")}</h1>
                <Button
                    onClick={returnDashboard}
                    style={customTheme.dividerMarginTop}
                    variant="contained"
                >
                    {t("success.button")}
                </Button>
            </>
        );
    }

    function CreatedComponent() {
        return (
            <>
                <h1>{t("success.headline.created")}</h1>
                <Button
                    onClick={returnDashboard}
                    style={customTheme.dividerMarginTop}
                    variant="contained"
                >
                    {t("success.button")}
                </Button>
            </>
        );
    }

    return (
        <div>
            {status === "updated" && <UpdatedComponent />}
            {status === "success" && <SuccessComponent />}
            {status === "cancel" && <CancelComponent />}
            {status === "created" && <CreatedComponent />}
        </div>
    );
}

export default Success;

import React from "react";
import {
    Grid,
    Container,
    Box, Typography,
} from "@mui/material";
import Headline from '../../shared/components/Headline';
// import { useTranslation } from 'react-i18next';

const Impressum = () => {
    // const { t } = useTranslation()
    
    return (
        <>
            <Grid container justifyContent="center" alignContent="center" direction="row" 
                sx={{position: "relative", backgroundColor: "white",}}
            >
                <Container>
                    <Grid
                        container spacing={3}
                        sx={{marginTop: "5vh", paddingLeft: "15%", paddingRight: "15%",}}
                    >
                        <Grid item xs={12}>
                            <Headline showLogo title={"Impressum"} />
                        </Grid>
                        

                        <Grid item xs={12} container justifyContent="center">
                            <Box sx={{width: "100%", height: "20px", textAlign: "center", marginBottom: "30px"}}>
                                <Typography component="span" sx={{fontSize: "15px", backgroundColor: "white", padding: "0 10px", color: "gray"}}>
                                    YOUniquehorns GmbH<br/>
                                    Germany, AG Ulm, HRB 732600<br/>
                                    CEO: Benjamin Steinvorth<br/>
                                    info@youniquehorns.com<br/>
                                </Typography>
                            </Box>
                        </Grid>
                        
                    </Grid>
                </Container>
            </Grid>
        </>
    );
};

export default Impressum;

import React, { useMemo, useState } from 'react';
import {Grid, Button, TextField, Container, Stack, Alert, Box, Typography, IconButton} from '@mui/material';
import { NavLink, useNavigate } from "react-router-dom";
import PasswordInput from '../../shared/components/PasswordInput';
import { LoadingButton } from '@mui/lab';
import { useAuthApiClient } from '../../clients/AuthApiClient';
import { useUserContext } from '../../shared/contexts/UserContext';
import { useUserApiClient } from '../../clients/UserApiClient';
import { ErrorResponse } from '../../clients/error/ErrorResponse';
import Headline from '../../shared/components/Headline';
import { validateEmail, validatePassword, validateRepeatPassword, validateUsername } from '../../shared/tools/validationTools';
import { useTranslation } from "react-i18next";
import {CredentialResponse, GoogleLogin} from "@react-oauth/google";
import { v4 as uuidv4 } from "uuid"
import jwt_decode from "jwt-decode";
import { AiOutlineGithub, AiOutlineInstagram, AiOutlineApple } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import FacebookLogin from "@greatsumini/react-facebook-login";
import {useTheme} from "@mui/material/styles";

export const icon = "/img/bb_solo.png"


const Signup = () => {
    const { t } = useTranslation()
    const theme = useTheme();
    
    const [signUpError, setSignUpError] = useState<string>();

    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState<string>();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<string>();
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState<string>();
    const [repeatPasswordError, setRepeatPasswordError] = useState<string>();
    const [repeatPassword, setRepeatPassword] = useState('');

    const [registerButtonLoading, setRegisterButtonLoading] = useState(false)


    const [fbSignupStarted, setFbSignupStarted] = useState<boolean>(false);
    const [fBAccessToken, setFBAccessToken] = useState<string>('');
    const [fbUserId, setFBuserId] = useState<string>('');
    const [fBemail, setFBemail] = useState<string>('');
    const [fBPictureURL, setFBPictureURL] = useState<string>('');

    // handleRegisterFacebook: signup as soon as both information are available
    React.useEffect(() => {
        if (!fbSignupStarted && fBAccessToken.length > 0 && fBemail.length > 0) {
            setFbSignupStarted(true)
            const randomPassword = uuidv4() || ""
            if (randomPassword.length > 0) {
                setRegisterButtonLoading(true)
                setSignUpError(undefined)
                userCtx.loginFacebook(fBAccessToken, fBPictureURL)
                authClient.signup({ idProvider: "FACEBOOK", username: "FACEBOOK_" + fbUserId, password: randomPassword, email: fBemail, sendMailToUser: true })
                    .then(() => userClient.getCurrentUser())
                    .then(userCtx.setCurrentUser)
                    .then(() => navigate('/dashboard'))
                    .catch((err: ErrorResponse) => setSignUpError("User already taken. Please _LOGIN_"))
                    .finally(() => setRegisterButtonLoading(false))
            }
        }
        // eslint-disable-next-line
    }, [fBAccessToken, fBemail])
    
    const authClient = useAuthApiClient({ preventNotification: true })
    const userClient = useUserApiClient()
    const userCtx = useUserContext()
    const navigate = useNavigate()

    const userNameValid = useMemo(() => {
        const validation = validateUsername(username)
        setUsernameError(validation.error)
        return validation.valid
    }, [username])

    const emailValid = useMemo(() => {
        const validation = validateEmail(email)
        setEmailError(validation.error)
        return validation.valid
    }, [email])

    const passwordValid = useMemo(() => {
        const validation = validatePassword(password)
        setPasswordError(validation.error)
        return validation.valid
    }, [password])


    const repeatPasswordValid = useMemo(() => {
        const validation = validateRepeatPassword(password, repeatPassword)
        setRepeatPasswordError(validation.error)
        return validation.valid
    }, [password, repeatPassword])


    const handleRegister = () => {
        if (userNameValid && emailValid && passwordValid && repeatPasswordValid) {
            const idProvider = "BOTBUCKET"
            
            setRegisterButtonLoading(true)
            setSignUpError(undefined)
            authClient.signup({ idProvider, username, password, email, sendMailToUser: true })
                .then(() => authClient.signin(username, password))
                .then(userCtx.login)
                .then(() => userClient.getCurrentUser())
                .then(userCtx.setCurrentUser)
                .then(() => navigate('/dashboard'))
                .catch((err: ErrorResponse) => setSignUpError(err.message))
                .finally(() => setRegisterButtonLoading(false))
        }
    }
    
    const handleRegisterGoogle = (credentialResponse: CredentialResponse) => {
        // this is a google ID-Token in JWT-format
        const google_access_token = credentialResponse.credential || ""
        
        const decoded: any = jwt_decode(google_access_token)
        
        const userGoogleEmail: string = decoded.email
        const userGooglePicture: string = decoded.picture
        const userGoogleName: string = decoded.name || ""
        
        const randomPassword = uuidv4() || ""
        
        if (validateUsername(userGoogleName).valid
            && validateEmail(userGoogleEmail).valid
            && randomPassword.length > 0
        ) {
            setRegisterButtonLoading(true)
            setSignUpError(undefined)
            userCtx.loginGoogle(google_access_token, userGooglePicture)
            authClient.signup({ idProvider: "GOOGLE", username: userGoogleName, password: randomPassword, email: userGoogleEmail, sendMailToUser: true })
                .then(() => userClient.getCurrentUser())
                .then(userCtx.setCurrentUser)
                .then(() => navigate('/dashboard'))
                .catch((err: ErrorResponse) => setSignUpError(err.message))
                .finally(() => setRegisterButtonLoading(false))
        }            
    }

    // https://medium.com/@manwarerutuj/react-google-login-deprecated-dont-worry-react-oauth-google-will-fix-it-1b2141252952
    /*const handleRegisterGoogle2 = useGoogleLogin({
        onSuccess: (codeResponse) => {
            // debugger
            console.log(codeResponse)
            setGoogleOauth2JWTToken(codeResponse.access_token)
            // codeResponse.expires_in
        },
        onError: (error) => console.log("Login Failed:", error),
        // flow: "auth-code"
    });*/

    // fetch user details for sign-in
    /*React.useEffect(() => {
        if (googleOauth2JWTToken && googleOauth2JWTToken.length > 0) {
            axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleOauth2JWTToken}`,
                    {
                        headers: {
                            Authorization: `Bearer ${googleOauth2JWTToken}`,
                            Accept: "application/json",
                        },
                    }
                )
                .then((res) => {
                    const userGoogleEmail: string = res.data.email
                    const userGooglePicture: string = res.data.picture
                    const userGoogleName: string = res.data.name

                    const randomPassword = uuidv4() || ""

                    if (validateUsername(userGoogleName).valid
                        && validateEmail(userGoogleEmail).valid
                        && randomPassword.length > 0
                    ) {
                        setRegisterButtonLoading(true)
                        setSignUpError(undefined)
                        userCtx.loginGoogle(googleOauth2JWTToken, userGooglePicture)
                        authClient.signup({ 
                            idProvider: "GOOGLE", 
                            username: userGoogleName, 
                            password: randomPassword, 
                            email: userGoogleEmail, 
                            sendMailToUser: true 
                        })
                            .then(() => userClient.getCurrentUser())
                            .then(userCtx.setCurrentUser)
                            .then(() => navigate('/dashboard'))
                            .catch((err: ErrorResponse) => setSignUpError(err.message))
                            .finally(() => setRegisterButtonLoading(false))
                    }
                })
                .catch((err) => console.log(err));
        }
        // eslint-disable-next-line
    }, [googleOauth2JWTToken]); */
    
    
    return (
        <>
            <Grid container justifyContent="center" alignContent="center" direction="row" sx={{
                position: "relative",
                backgroundColor: "white",
                zIndex: 10,
            }}>
                <Container >
                    <Grid container
                        spacing={3}
                        sx={{ marginTop: "5vh" }}
                        onKeyDownCapture={(e) => {
                            if (e.key === 'Enter') {
                                handleRegister()
                            }
                        }}>
                        <Grid item xs={12} >
                            <Headline showLogo title={t('login.signUp')} />
                        </Grid>
                        {signUpError &&
                            <Grid item xs={12} container justifyContent='center'>
                                {(signUpError.indexOf('_LOGIN_') > 0 ?
                                    <Box sx={{color: theme.palette.primary.main}}>
                                        {t('login.userAlreadyTaken') + " "}
                                        <Button sx={{ width: '200px', fontSize: '16px' }} variant="outlined" component={NavLink} to="/login">
                                            {t('login.navToLogin')}
                                        </Button>
                                    </Box>
                                    : <Alert severity="error">{signUpError}</Alert>)}
                            </Grid>
                        }
                        <Grid item xs={12} container spacing={2} justifyContent="center">
                            <Grid item>
                                <IconButton>
                                    <GoogleLogin
                                        size={'large'}
                                        type ={'icon'}
                                        shape={'pill'}
                                        onSuccess={handleRegisterGoogle}
                                        onError={() => {
                                            console.log('Login Failed');
                                        }}
                                    />
                                </IconButton>
                            </Grid>

                            <Grid item>
                                <FacebookLogin
                                    appId="867462195031455"
                                    onSuccess={(response) => {
                                        setFBAccessToken(response.accessToken)
                                        console.log('Login Success!', response);
                                        // response. holds a HMAC SHA-256-signed object which can be validated by backend.
                                    }}
                                    onFail={(error) => {
                                        console.log('Login Failed!', error);
                                    }}
                                    onProfileSuccess={(response) => {
                                        setFBemail(response.email || "")
                                        setFBPictureURL(response.picture?.data.url || "")
                                        setFBuserId(response.id || "")
                                        console.log('Get Profile Success!', response);
                                    }}
                                    render={({ onClick, logout }) => (
                                        <IconButton
                                            size="large" edge="end" color="inherit"
                                            onClick={onClick}
                                        >
                                            <BsFacebook color={"#1877F2"}/>
                                        </IconButton>
                                    )}
                                />
                            </Grid>

                            <Grid item>
                                <IconButton
                                    size="large" edge="end" color="inherit"
                                    onClick={() => console.log("Insta")}
                                >
                                    <AiOutlineInstagram/>
                                </IconButton>
                            </Grid>

                            <Grid item>
                                <IconButton
                                    size="large" edge="end" color="inherit"
                                    onClick={() => console.log("Apple")}
                                >
                                    <AiOutlineApple/>
                                </IconButton>
                            </Grid>

                            <Grid item>
                                <IconButton
                                    size="large" edge="end" color="inherit"
                                    onClick={() => console.log("Github")}
                                >
                                    <AiOutlineGithub/>
                                </IconButton>
                            </Grid>
                            
                        </Grid>

                        <Grid item xs={12} container justifyContent="center">
                            <Box sx={{width: "50%", height: "20px", borderBottom: "1px dashed gray", textAlign: "center", marginBottom: "30px"}}>
                                <Typography component="span" sx={{fontSize: "15px", backgroundColor: "white", padding: "0 10px", color: "gray"}}>
                                    OR
                                </Typography>
                            </Box>
                        </Grid>
                        
                        <Grid item xs={12} container justifyContent='center'>
                            <Stack spacing={2} sx={{ width: "50%" }}>
                                <TextField
                                    label={t('login.username')}
                                    variant="outlined"
                                    fullWidth
                                    value={username}
                                    error={!!usernameError}
                                    helperText={usernameError ?? ""}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                                <TextField
                                    label={t('login.mail')}
                                    variant="outlined"
                                    fullWidth
                                    value={email}
                                    error={!!emailError}
                                    helperText={emailError ?? ""}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <PasswordInput
                                    error={!!passwordError}
                                    errortext={passwordError}
                                    title={t('login.password')}
                                    sx={{ width: '100%' }}
                                    password={password}
                                    onPasswordChange={setPassword}
                                    onSubmit={() => handleRegister()}
                                />
                                <PasswordInput
                                    error={!!repeatPasswordError}
                                    errortext={repeatPasswordError}
                                    title={t('login.passwordRepeat')}
                                    sx={{ width: '100%' }}
                                    password={repeatPassword}
                                    onPasswordChange={setRepeatPassword}
                                    onSubmit={() => handleRegister()}
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} spacing={2} container justifyContent='center'>
                            <Grid item>
                                <Button sx={{ width: '200px', fontSize: '16px' }} variant="outlined" component={NavLink} to="/login">
                                    {t('login.navToLogin')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    sx={{ width: '200px', fontSize: '16px' }}
                                    disabled={!(userNameValid && emailValid && passwordValid && repeatPasswordValid)}
                                    onClick={handleRegister}
                                    loading={registerButtonLoading}
                                >
                                    {t('login.signUp')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </>
    );
};

export default Signup;
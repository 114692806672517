import * as React from 'react';
import Hero from "./Hero";
import Section from "./Section";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Tech from "./Tech";

export interface IHomeProps {
}

const Home: React.FunctionComponent<IHomeProps> = (IHomeProps) => {

    // <Testimonial />
    return (
        <>
            <Hero />

            <Section />
            
            <Tech />

            <AboutUs />

            <ContactUs />
        </>
    );
}

export default Home;

import * as React from 'react';
import { Chatbot, Embeddings } from '../../../model/Chatbot';
import { LoadingButton } from '@mui/lab';
import {
    Dialog,
    DialogContent,
    Grid,
    Alert,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField
} from '@mui/material';
import DialogHeader from '../../../shared/components/dialogs/DialogHeader';
import { useState } from 'react';
import { useChatbotApiClient } from '../../../clients/ChatbotApiClient';
import { ErrorResponse } from '../../../clients/error/ErrorResponse';
import { useTranslation } from 'react-i18next';

export interface ITrainChatbotModalProps {
    chatbot: Chatbot
    open?: boolean
    onClose?(): void
}

const defaultEmbedding = Embeddings.OPEN_AI_TE_3_LARGE
const defaultChunksize = 750
const defaultChunkOverlap = 0

const TrainChatbotModal: React.FunctionComponent<ITrainChatbotModalProps> = (props: ITrainChatbotModalProps) => {

    const chatbotClient = useChatbotApiClient()
    const { t } = useTranslation()

    const [pageError, setPageError] = useState<string>()
    const [embeddings, setEmbeddings] = useState<Embeddings>(defaultEmbedding)
    const [chunkSize, setChunkSize] = useState<number>(defaultChunksize)
    const [chunkOverlap, setChunkOverlap] = useState<number>(defaultChunkOverlap)
    const [loading, setLoading] = useState(false)


    const onClose = () => {
        setPageError(undefined)
        setEmbeddings(defaultEmbedding)
        setChunkSize(defaultChunksize)
        setChunkOverlap(defaultChunkOverlap)
        props.onClose && props.onClose()
    }

    const startTraining = () => {
        setLoading(true)
        chatbotClient.trainChatbot(props.chatbot.id, { embeddings, chunkSize, chunkOverlap })
            .then(() => onClose())
            .catch((e: ErrorResponse) => setPageError(e.message))
            .finally(() => setLoading(false))
    }

    return (
        <>
            <Dialog
                open={!!(props.open)}
                PaperProps={{ sx: { backgroundColor: "white" } }}
                disableEnforceFocus
                fullWidth
                maxWidth={'sm'}
                scroll="paper"
                onClose={onClose}
            >
                <DialogHeader
                    headline={t('chatbot.trainChatbot.trainYourChatbot')}
                    closeDialog={onClose} />
                <DialogContent>
                    <Grid container spacing={2} justifyContent={"center"}>
                        <Grid item xs={12}>
                            {" "}
                        </Grid>
                        {pageError &&
                            <Grid item xs={12} container justifyContent='center'>
                                <Alert severity="error">
                                    {pageError}
                                </Alert>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="embedding-label">{t('chatbot.trainChatbot.embeddings')}</InputLabel>
                                <Select
                                    labelId="embedding-label"
                                    value={embeddings}
                                    onChange={(e) => setEmbeddings(e.target.value as Embeddings)}
                                    label={t('chatbot.trainChatbot.embeddings')}
                                >
                                    {Object.values(Embeddings).map(e => <MenuItem key={e} value={e}>
                                        {e}
                                    </MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth>
                                <TextField
                                    type='number'
                                    value={chunkSize}
                                    onChange={(e) => setChunkSize(+e.target.value)}
                                    label={"Chunk-size (chars)"}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth>
                                <TextField
                                    type='number'
                                    value={chunkOverlap}
                                    onChange={(e) => setChunkOverlap(+e.target.value)}
                                    label={"Chunk Overlap (chars)"}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent={"right"}>
                        <Grid item>
                            <LoadingButton loading={loading} onClick={() => startTraining()} variant="contained">
                                {t('chatbot.trainChatbot.startTraining')}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default TrainChatbotModal;
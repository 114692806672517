import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import useHomeHeaderStyles from "../../../shared/styles";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";


const aboutUsImage = "/img/Books.png"


const AboutUs = () => {
    const classes = useHomeHeaderStyles();
    const { t } = useTranslation();

    return (
        <Box sx={classes.aboutUsContainer}>
            <Grid container spacing={6} sx={classes.gridContainer}>
                <Grid item xs={12} md={5}>
                    <Box src={aboutUsImage} component="img" sx={{display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '100%'}}/>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="h3" fontWeight={700} sx={classes.title}>
                        {t('homepage.aboutUs0')}
                    </Typography>
                    <Typography sx={classes.aboutUsSubtitle}>
                        {t('homepage.aboutUs1')}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ width: '200px', fontSize: '16px' }}
                        component={NavLink} to="/contact"
                    >
                        {t('homepage.aboutUs2')}
                    </Button>
                    
                    
                </Grid>

                
            </Grid>
        </Box>
    );
};

export default AboutUs;
import { LoadingButton } from '@mui/lab';
import { Grid, Container, Button, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Headline from '../../shared/components/Headline';
import { useMemo, useState } from 'react';
import { useAuthApiClient } from '../../clients/AuthApiClient';
import { validateEmail } from '../../shared/tools/validationTools';
import { useTranslation } from 'react-i18next';

export interface IRequestPasswordResetProps {
}

const RequestPasswordReset: React.FunctionComponent<IRequestPasswordResetProps> = (props: IRequestPasswordResetProps) => {
    const { t } = useTranslation()

    const authClient = useAuthApiClient()

    const [loading, setLoading] = useState(false)
    const [requested, setRequested] = useState(false)

    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState<string>();

    const emailValid = useMemo(() => {
        const validation = validateEmail(email)
        setEmailError(validation.error)
        return validation.valid
    }, [email])

    const handleResetRequest = () => {
        if (emailValid) {
            setLoading(true)
            authClient.requestPasswordReset(email)
                .catch(() => { })
                .finally(() => {
                    setLoading(false)
                    setRequested(true)
                })
        }
    }

    if (requested) {
        return (
            <>
                <Grid container justifyContent="center">
                    <Container >
                        <Grid container spacing={3} sx={{ marginTop: "5vh", paddingLeft: "15%", paddingRight: "15%" }}
                            onKeyDownCapture={(e) => {
                                if (e.key === 'Enter') {
                                    handleResetRequest()
                                }
                            }}>
                            <Grid item xs={12}>
                                <Headline showLogo title={t('login.passwordResetted')} />
                            </Grid>
                            <Grid item xs={12} container justifyContent='center'>
                                <Typography>
                                    {t('login.thanksWeSentResetMail')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} spacing={2} container justifyContent='center'>
                                <Grid item>
                                    <Button sx={{ width: '200px', fontSize: '16px' }} variant="outlined" component={NavLink} to="/login">
                                        {t('login.navToLogin')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </>
        );
    }


    return (
        <>
            <Grid container justifyContent="center">
                <Container >
                    <Grid container spacing={3} sx={{ marginTop: "5vh", paddingLeft: "15%", paddingRight: "15%" }}
                        onKeyDownCapture={(e) => {
                            if (e.key === 'Enter') {
                                handleResetRequest()
                            }
                        }}>
                        <Grid item xs={12}>
                            <Headline showLogo title={t('login.resetPassword')} />
                        </Grid>
                        <Grid item xs={12} container justifyContent='center'>
                            <TextField
                                label={t('login.mail')}
                                variant="outlined"
                                fullWidth
                                value={email}
                                error={!!emailError}
                                helperText={emailError ?? ""}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} spacing={2} container justifyContent='center'>
                            <Grid item>
                                <Button sx={{ width: '200px', fontSize: '16px' }} variant="outlined" component={NavLink} to="/login">
                                    {t('login.navToLogin')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    loading={loading}
                                    variant="contained"
                                    color="error"
                                    sx={{ width: '200px', fontSize: '16px' }}
                                    onClick={handleResetRequest}
                                    disabled={!emailValid}
                                >
                                    {t('login.reset')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </>
    );
}

export default RequestPasswordReset;


const messageContainerUser = {
    maxWidth: '99%',
    paddingTop: '0.2em',
    paddingBottom: '0em',
    paddingLeft: '1em',
    paddingRight: '1em',
    borderTopLeftRadius: "17px",
    borderTopRightRadius: "17px",
    borderBottomLeftRadius: "17px",
    overflowWrap: "break-word",
    alignSelf: "flex-end",
}

const messageContainerBot = {
    maxWidth: '99%',
    paddingTop: '0.2em',
    paddingBottom: '0em',
    paddingLeft: '1em',
    paddingRight: '1em',
    borderTopLeftRadius: "17px",
    borderTopRightRadius: "17px",
    borderBottomRightRadius: "17px",
    overflowWrap: "break-word",
    alignSelf: 'flex-start',
}

const messageContainerWait = {
    maxWidth: "100%",
    padding: "0.5em",
    borderRadius: "17px",
    overflowWrap: "break-word",
    textAlign: 'center',
    display: "inline-block"
}

const messageContainerOptions = {
    background: "white",
    alignItems: "center",
}

const messageContainerOptionsP = {
    padding: "0.5em",
    marginRight: "0.8em",
    background: "#393e46",
    color: "#f7f7f7",
    borderRadius: "17px",
    maxWidth: "750px",
    display: "inline-block",
}

export {
    messageContainerUser,
    messageContainerBot,
    messageContainerWait,
    messageContainerOptions,
    messageContainerOptionsP,
}
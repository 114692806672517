import React, { useEffect, useState, FunctionComponent, ReactNode } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { keyframes } from '@mui/system';

// Define the pulsating keyframes
const pulsate = keyframes`
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
    }
    50% {
        transform: scale(1.5);
        box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
    }
`;

interface PulsatingButtonProps extends ButtonProps {
    children: ReactNode;
}

// Create a wrapper that handles the pulsating animation
const PulsatingButtonWrapper: FunctionComponent<PulsatingButtonProps> = ({
                                                                             children,
                                                                             startIcon,
                                                                             ...props
                                                                         }) => {
    const [pulsating, setPulsating] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => setPulsating(false), 6000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Button
            {...props} // Pass down all ButtonProps like sx, color, onClick, etc.
            sx={{
                animation: pulsating ? `${pulsate} 1.5s infinite` : undefined,
                ...props.sx, // Ensure we merge any additional sx styles passed in
            }}
        >
            {children}
        </Button>
    );
};

// Tooltip wrapping the PulsatingButton
const PulsatingButton: FunctionComponent<PulsatingButtonProps> = (props) => {
    return (
        <PulsatingButtonWrapper {...props} />
    );
};

export default PulsatingButton;

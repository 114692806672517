import { Check, Close } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface IIncludedInTrainingIconProps {
    included?: boolean
}

const IncludedInTrainingIcon: React.FunctionComponent<IIncludedInTrainingIconProps> = (props: IIncludedInTrainingIconProps) => {
    const { t } = useTranslation()
    if (props.included) {
        return (
            <>
                <Tooltip title={t('chatbot.includedInTraining')} >
                    <Check color="success" />
                </Tooltip>
            </>
        );
    }

    return (
        <>
            <Tooltip title={t('chatbot.notIncludedInTraining')} >
                <Close color="error" />
            </Tooltip>
        </>
    );
}

export default IncludedInTrainingIcon;
import React from "react";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { useCustomThemeProps } from "../useCustomThemeProps";

interface Props {
    currency: string;
    changeCurrency: (
        event: React.MouseEvent<HTMLElement>,
        newCurrency: string
    ) => void;
}

const CurrencyToggleButtonGroup: React.FC<Props> = ({
    currency,
    changeCurrency,
}) => {
    const customTheme = useCustomThemeProps();

    return (
        <ToggleButtonGroup
            sx={customTheme.toggleButtonGroup}
            value={currency}
            exclusive
            onChange={changeCurrency}
            aria-label="text alignment"
        >
            <ToggleButton
                value="eur"
                aria-label="centered"
                sx={customTheme.toggleButton}
            >
                €
            </ToggleButton>
            <ToggleButton
                value="usd"
                aria-label="centered"
                sx={customTheme.toggleButton}
            >
                $
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default CurrencyToggleButtonGroup;

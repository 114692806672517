import {Grid} from '@mui/material';
import * as React from 'react';
// import {useTranslation} from "react-i18next";
import {useRef} from "react";
import {useHelperBotContext} from "../contexts/HelperBotContext";
import {useTranslation} from "react-i18next";

export interface IHelperBotProps {
}

const HelperBot: React.FunctionComponent<IHelperBotProps> = (props: IHelperBotProps) => {

    const { i18n } = useTranslation()
    // eslint-disable-next-line
    const {questionToAsk, setQuestionToAsk } = useHelperBotContext()
    
    const [chatbotOpen, setChatbotOpen] = React.useState<boolean>(false)
    
    // const { t } = useTranslation();
    const iFrameRef = useRef<HTMLIFrameElement>(null);
    

    // send a new question to iFrame chatbot
    React.useEffect(() => {
        if (iFrameRef && iFrameRef.current && iFrameRef.current.contentWindow) {
            iFrameRef.current.contentWindow.postMessage(
                {type: 'questionToAsk', message: questionToAsk}, 
                '*'
            )    
        }
        // eslint-disable-next-line
    }, [questionToAsk])

    // send new language to iFrame chatbot
    React.useEffect(() => {
        console.log("Lang is now: "+  i18n.language)

        if (iFrameRef && iFrameRef.current && iFrameRef.current.contentWindow) {
            iFrameRef.current.contentWindow.postMessage(
                {type: 'languageChange', message: i18n.language},
                '*'
            )
        }
        // eslint-disable-next-line
    }, [i18n.language]);

    // listen for postMessage from iFrame to parent (here) stating if the chatbot is open or not
    const handler = ((e: MessageEvent) => {
        if (e.data.type !== 'chatbotIsOpen') return
        setChatbotOpen((e.data.message === "true"))
    }) as EventListener

    React.useEffect(() => {
        window.addEventListener("message", handler);
        return () => window.removeEventListener('message', handler)
        // eslint-disable-next-line
    }, []);


    // Sp: a2ed0962-0cf6-44f4-9f1c-46f596dee46b
    // src={"http://localhost:3000/bot/a03c7e06-6153-43cb-b2a5-7481718d4c0b/chat/iframe/undecorated"} 
    // https://neondigitalarts.com/how-to-make-a-gif-using-gimp-software/
    return (
            <Grid container justifyContent={"right"}
                  sx={{position: "fixed", right: "20px", bottom: "40px", zIndex: 1000,
                      width: (chatbotOpen ? "510px" : "50px"),
                      height: (chatbotOpen ? "450px" : "150px") }}
            >
                <iframe title={"HelperBot"} 
                        src={"https://int.botbucket.de/bot/a03c7e06-6153-43cb-b2a5-7481718d4c0b/chat/iframe/undecorated"} 
                        style={{borderWidth: "0px", width: (chatbotOpen ? "500px" : "50px")}}
                        allowTransparency
                        allow={"microphone; autoplay"}
                        ref={iFrameRef}
                />
            </Grid >
    );
}

export default HelperBot;
import React from 'react';
import { Typography } from "@mui/material";
import { useCustomThemeProps } from '../useCustomThemeProps';

interface PriceListProps {
    filteredPrices: any[];  
    currency: string;
    t: (key: string) => string; 
}

const PriceList: React.FC<PriceListProps> = ({
    filteredPrices,
    currency,
    t,
}) => {

    function formatPrice(price: number): string {
        const priceInCents = price % 100;
        const priceInDollars = (price - priceInCents) / 100;
        return `${priceInDollars},${
            priceInCents < 10 ? "0" : ""
        }${priceInCents}`;
    }

    const customTheme = useCustomThemeProps();

    return (
        <>
            {filteredPrices.map((price) => (
                <Typography
                    variant="body1"
                    key={price.id}
                    align="center"
                    sx={customTheme.dividerMarginTop}
                >
                    {t("prices.price")}: {formatPrice(price.unitAmount)}
                    {currency === "usd" ? "$" : "€"}
                </Typography>
            ))}
        </>
    );
};

export default PriceList;

import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface SpeechBubbleProps {
    text: string;
    onClose: () => void;
    position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

const SpeechBubble: React.FC<SpeechBubbleProps> = ({ text, onClose, position = 'top-right' }) => {
    const getPositionStyles = () => {
        switch (position) {
            case 'top-left':
                return { top: '0', left: '0' };
            case 'top-right':
                return { top: '0', right: '0' };
            case 'bottom-left':
                return { bottom: '0', left: '0' };
            case 'bottom-right':
                return { bottom: '25px', right: '-10px' };
            default:
                return { top: '0', right: '0' };
        }
    };

    return (
        <Box
            sx={{
                position: 'absolute',
                padding: '10px',
                paddingRight: '20px', // Add padding on the right for the close icon
                backgroundColor: '#f0f0f0',
                borderRadius: '10px',
                width: '300px', // Explicitly set width to make the bubble wider
                
                ...getPositionStyles(),
            }}
        >
            <IconButton
                size="small"
                onClick={onClose}
                sx={{ position: 'absolute', top: '5px', right: '5px' }}
            >
                <CloseIcon />
            </IconButton>
            <Typography variant="body2" sx={{ fontSize: '0.675rem' }} dangerouslySetInnerHTML={{ __html: text }} />
            <Box
                sx={{
                    position: 'absolute',
                    width: '0',
                    height: '0',
                    borderLeft: '10px solid transparent',
                    borderRight: '10px solid transparent',
                    borderTop: '10px solid #f0f0f0', // Match the background color
                    bottom: '-10px',
                    right: '20px',
                }}
            />
        </Box>
    );
};

export default SpeechBubble;

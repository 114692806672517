import { SxProps, useTheme } from '@mui/material/styles';

const useHomeHeaderStyles = () => {
    const theme = useTheme();

    const toolBar = {
        height: '10vh',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px',
        backgroundColor: 'white',
    };

    const logo = {
        color: theme.palette.error.main,
        cursor: 'pointer',
    };

    const link = {
        color: "green"
    };

    const menuIcon = {
    };

    const formContainer = {
        flexGrow: 1,
        padding: '10px',
        maxWidth: '700px',
        margin: '30px auto',
        /*[theme.breakpoints.between('xs', 'sm')]= {
            width: '100%',
        },*/
    };
    const form = {
        marginTop: '30px',
    };
    const formHeading = {
        textAlign: 'center',
    };
    const heroBox = {
        width: '100%',
        display: 'flex',
        minHeight: '600px',
        alignItems: 'center',
        justifyContent: 'center',
    };
    const gridContainer = {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '1300px',
        padding: '50px',
    };
    const aboutUsContainer = {
        width: '100%',
        display: 'flex',
        minHeight: '400px',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '30px 0px 50px 0px',
    };
    const aboutUsSubtitle = {
        opacity: '0.7',
        paddingBottom: '30px',
        fontSize: '18px',
    };
    const title = {
        paddingBottom: '15px',
        color: theme.palette.error.main,
    };
    const subtitle = {
        opacity: '0.4',
        paddingBottom: '30px',
    };
    const largeImage = {
        width: '80%',
    };
    const sectionGridContainer = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: '500px',
    };
    const sectionGridItem = {
        backgroundColor: '#f2f0f1',
        textAlign: 'center',
        padding: '30px',
        width: '200px',
        borderRadius: '10px',
        margin: '10px !important',
    };
    const inputField = {
        marginBottom: '20px !important',
    };
    const textArea = {
        width: '100%',
        marginBottom: '20px',
        fontSize: '16px',
        padding: '10px',
    };
    const footerContainer: SxProps = {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        height: '10vh',
        padding: '20px',
        justifyContent: 'center',
        backgroundColor: '#f2f0f1',
        flexDirection: 'column',
        width: "100%"
    };
    const footerText = {
        paddingBottom: '10px',
    };
    const footerDate = {
        opacity: '0.4',
    };
    const testimonialCard = {
        backgroundColor: '#fff',
        padding: '10px',
        minHeight: '200px',
        display: 'flex',
        alignItems: 'center',
    };
    const testimonialStatement = {
        paddingBottom: '25px',
        opacity: '0.7',
        fontSize: '14px',
    };
    const avatar = {
        marginRight: '10px',
    };
    const testimonialPosition = {
        fontSize: '14px',
        opacity: '0.6',
    };

    return {
        toolBar,
        logo,
        link,
        menuIcon,
        formContainer,
        form,
        formHeading,
        heroBox,
        gridContainer,
        aboutUsContainer,
        aboutUsSubtitle,
        title,
        subtitle,
        largeImage,
        sectionGridContainer,
        sectionGridItem,
        inputField,
        textArea,
        footerContainer,
        footerText,
        footerDate,
        testimonialCard,
        testimonialStatement,
        avatar,
        testimonialPosition

    }
};

export default useHomeHeaderStyles;
import { IconButton, TextField, Typography, Card, Tooltip } from '@mui/material';
import * as React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';

export interface ICopyInputFieldProps {
    label?: string
    text: string
}

const CopyInputField: React.FunctionComponent<ICopyInputFieldProps> = (props: ICopyInputFieldProps) => {
    const [copied, setCopied] = React.useState(false)
    const {t} = useTranslation()
    return (
        <>
            <TextField
                InputProps={{
                    endAdornment: <>
                        <Tooltip hidden={copied} title="Copy to clipboard">
                            <IconButton disabled={copied} onClick={() => {
                                navigator.clipboard.writeText(props.text)
                                    .then(() => {
                                        setCopied(true)
                                        setTimeout(() => setCopied(false), 2000)
                                    })
                            }} >
                                <ContentCopyIcon />
                                {copied &&
                                    <Card elevation={3} sx={{
                                        position: "absolute",
                                        left: "3em",
                                        backgroundColor: (theme) => theme.palette.background.paper
                                    }} >
                                        <Typography variant="body1" align="center">{t('common.copiedToClipboard')}</Typography>
                                    </Card>}
                            </IconButton>
                        </Tooltip>
                    </>
                }}
                sx={{marginTop: "10px"}}
                size="small"
                fullWidth={true}
                disabled
                label={props.label}
                defaultValue={props.text}
            />
        </>
    );
}

export default CopyInputField;
import * as React from 'react';
import { TrainingStatus } from '../../../model/Chatbot';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { Cancel, Pending, CheckCircle, Error } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export interface ITrainingStatusIconProps {
    status: TrainingStatus
}

const TrainingStatusIcon: React.FunctionComponent<ITrainingStatusIconProps> = (props: ITrainingStatusIconProps) => {
    const { t } = useTranslation()
    return React.useMemo(() => {
        switch (props.status) {
            case TrainingStatus.NOT_TRAINED:
                return <Tooltip title={t('chatbot.trainingStatus.notTrained')}>
                    <span>
                        <IconButton disabled>
                            <Cancel sx={{ fontSize: '1.5em', color: theme => theme.palette.warning.dark }} />
                        </IconButton>
                    </span>
                </Tooltip>
            case TrainingStatus.PENDING:
                return <Tooltip title={t('chatbot.trainingStatus.pending')}>
                    <span>
                        <IconButton disabled>
                            <Pending sx={{ fontSize: '1.5em', color: theme => theme.palette.warning.dark }} />
                        </IconButton>
                    </span>
                </Tooltip>
            case TrainingStatus.TRAINING:
                return <Tooltip title={t('chatbot.trainingStatus.training')} >
                    <CircularProgress size={"2em"} sx={{ padding: "0.125em", color: theme => theme.palette.primary.main }} />
                </Tooltip >
            case TrainingStatus.TRAINED:
                return <Tooltip title={t('chatbot.trainingStatus.trained')}>
                    <span>
                        <IconButton disabled>
                            <CheckCircle sx={{ fontSize: '1.5em', color: theme => theme.palette.success.main }} />
                        </IconButton>
                    </span>
                </Tooltip>
            case TrainingStatus.FAILED:
                return <Tooltip title={t('chatbot.trainingStatus.failed')}>
                    <span>
                        <IconButton disabled>
                            <Error sx={{ fontSize: '1.5em', color: theme => theme.palette.error.main }} />
                        </IconButton>
                    </span>
                </Tooltip>
        }
    }, [props.status, t])
}

export default TrainingStatusIcon;
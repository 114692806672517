import React from 'react';
import {Grid, Typography, Button, Box, Fade} from '@mui/material';
import useHomeHeaderStyles from "../../../shared/styles";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SyntaxHighlighter from 'react-syntax-highlighter';
import {docco} from "react-syntax-highlighter/dist/cjs/styles/hljs";

const landingImage = "/img/bb.png"

const Docs = () => {
    const classes = useHomeHeaderStyles();
    const { t } = useTranslation();
    
    const iFrameCode = 
        "<html><body style=\"background-color: gray;\">\n" +
        "\tThis is my Homepage!\n\n" +
        "\t<!-- put the bot eg in the lower right corner of your homepage -->\n" +
        "\t<div style=\"position: fixed; right: 20px; bottom: 40px; z-index: 1000;\">\n" +
        "\t\t<!-- replace {botID} by your botbucket.de chat id -->\n" +
        "\t\t<iframe \n" +
        "\t\t\ttitle=\"HelperBot\"\n" +
        "\t\t\tsrc=\"https://int.botbucket.de/bot/{botID}/chat/iframe/undecorated\"\n" +
        "\t\t\tstyle=\"border-width: 0px; width: 500px; height: 450px;\"\n" +
        "\t\t\tallowTransparency\n" +
        "\t\t></iframe>\n" +
        "\t</div>\n" +
        "</body></html>"

    return (
        <Box sx={classes.heroBox}>
            <Grid container spacing={12} sx={classes.gridContainer}>

                <Grid item xs={3} >
                    <Typography variant="h6" gutterBottom>
                        Chatbot Integration
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        On BotBucket Chat-page
                    </Typography>
                </Grid>
                <Grid item xs={9} >
                    <Typography variant="body1" gutterBottom>
                        Any ChatBot you create can be accessed on a page available on botbucket.de. 
                    </Typography>
                </Grid>
                
                
                <Grid item xs={3} >
                    <Typography variant="h6">
                        Chatbot Integration
                    </Typography>
                    <Typography variant="subtitle1">
                        Make Chatbot public available
                    </Typography>
                </Grid>
                <Grid item xs={9} >
                    <Typography variant="body1" gutterBottom>
                        You can control the access to your chatbot in the chatbot settings: Enable the "public" switch so anyone can access your bot from the internet. If you disable that switch only you can chat with that bot.
                        <Grid item xs={12} container >
                            <Box src={"/img/doc/publicChatbot.gif"} component="img"
                                 sx={{ width: '350px'}}
                            />
                        </Grid>
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="h6" gutterBottom>
                        Chatbot Integration
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        In your Website
                    </Typography>
                </Grid>
                <Grid item xs={9} >
                    <Typography variant="body1" gutterBottom>
                        You can put your BotBucket.de chatbot on any website - for example your homepage. Just follow 
                        these steps:
                        <ul>
                            <li>Create a BotBucket.de Chatbot</li>
                            <li>Go to the "Settings" dialog, Tab "iFrame Integration"</li>
                            <li>Copy the code on your homepage.</li>
                        </ul>
                        
                        Just ensure
                        that you have 
                        To embedd your chatbot on any website (eg your homepage) you can implement the botbucket.de iframe. 
                        
                        Just add this code to your homepage:
                    </Typography>
                    <SyntaxHighlighter language="typescript" style={docco}>
                        {iFrameCode}
                    </SyntaxHighlighter>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Fade in={true} timeout={1000}>
                        <Grid item sx={{color: '#E3E3E3', fontSize: '7px', textAlign: 'center'}}>
                            <Box src={landingImage} component="img" sx={{ display: 'block', marginLeft: 'auto', marginRight: 'auto'}}/>
                            A cute robot whose head is a bucket with big eyes who is happy because books are falling from the sky in digital art
                        </Grid>
                    </Fade>

                </Grid>
                <Grid item xs={12} md={7}>
                    <Typography variant="h3" fontWeight={700} sx={classes.title}>
                        BotBucket
                    </Typography>
                    <Typography variant="h6" sx={classes.aboutUsSubtitle}>
                        {t('homepage.hero0')}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ width: '200px', fontSize: '16px', color: 'white' }}
                        component={NavLink} to="/register"
                    >
                        {t('homepage.hero1')}
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{ width: '200px', fontSize: '16px' }}
                        component={NavLink} to="/login"
                    >
                        Login
                    </Button>
                </Grid>

            </Grid>
        </Box>
    );
};

export default Docs;
import React, { useState, useRef } from "react";
import { UploadFile } from "@mui/icons-material";
import { Box, Grid, SxProps, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";


export interface DragAndDropProps {
    multiple?: boolean;
    handleDrop: (files: File[]) => void;
    title?: string
    disabled?: boolean
    sx?: SxProps
    iconFontSize?: string
    accept?: string
}

const DragAndDrop: React.FunctionComponent<DragAndDropProps> = (
    props: DragAndDropProps
) => {
    const { t } = useTranslation()

    const [isDragHover, setIsDragHover] = useState(false);
    const [dragCounter, setDragCounter] = useState(0);
    const inputFile = useRef<HTMLInputElement>(null);


    const stopEvents = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        stopEvents(e);
    };

    const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        stopEvents(e);
        setDragCounter(dragCounter + 1);
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setIsDragHover(true);
        }
    };

    const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        stopEvents(e);
        const newDragCounter = dragCounter - 1;
        setDragCounter(newDragCounter);
        if (newDragCounter === 0) {
            setIsDragHover(false);
        }
    };

    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
        stopEvents(e);
        setIsDragHover(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            checkFileType(e.dataTransfer.files);
            e.dataTransfer.clearData();
            setDragCounter(0);
        }
    };

    const checkFileType = (files: FileList) => {
        const filesArray: File[] = [];
        for (const entry of Array.from(files)) {
            filesArray.push(entry);
        }
        props.handleDrop(filesArray);
    };

    const onClick = () => {
        inputFile.current?.click();
    };

    const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }

        checkFileType(e.target.files);
    };

    return (
        <Box component="div" sx={props.sx}>
            <input
                disabled={props.disabled}
                type="file"
                name="file"
                ref={inputFile}
                multiple={props.multiple}
                accept={props.accept}
                onChange={handleFileSelected}
                style={{ display: "none" }}
            />
            <Grid
                onDragEnter={onDragEnter}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                onClick={onClick}
                container
                sx={{
                    borderWidth: "2px",
                    borderStyle: "dashed",
                    "&:hover": props.disabled ? {} : {
                        background: "rgba(238, 238, 238, 0.8)",
                        cursor: "pointer"
                    },
                    borderRadius: "4px",
                    color: theme => props.disabled ? theme.palette.grey[400] : theme.palette.error.main,
                    borderColor: theme => props.disabled ? theme.palette.grey[400] : theme.palette.primary.main,
                    background: isDragHover ? "rgba(238, 238, 238, 0.8)" : "rgba(255, 255, 255, 0.4)",
                    backdropFilter: "blur(6px)",
                    width: "100%",
                    height: "100%",
                    padding: "2em",
                }}
            >
                <Grid item xs={12} container justifyContent="center">
                    <UploadFile sx={{ fontSize: props.iconFontSize ?? 40 }} />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                    {props.title && <Grid item xs={12}>
                        <Typography variant="h6" align="center" >{props.title}</Typography>
                    </Grid>}
                    <Grid item xs={12}>
                        <Typography variant="body1" align="center">
                            {t('dragAndDrop.chooseFile')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DragAndDrop;
